import * as React from "react";
import {useEffect, useState} from "react";
import {IMediaFile, MediaApi} from "../../api/MediaApi";
import {withVerifyAccess} from "../common/withVerifyAccess";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {setHeaderNotification} from "../base/header/HeaderActions";
import {NotificationTypes} from "../base/header/HeaderReducer";
import {useDispatch, useSelector} from "react-redux";
import {MediaFileUpload} from "./MediaFileUpload";
import {ErrorComponent} from "../base/Error.component";
import {performanceReportingEnabled} from "../../../mainReducerMapSelectors";


export const PlanSpecificMediaFilesPage: React.FunctionComponent = () => {
    const performanceReportingEnabledAccess = useSelector(state => performanceReportingEnabled(state));
    const [planSpecificMediaFiles, setPlanSpecificMediaFiles] = useState<IMediaFile[]>([]);
    const [showFileUpload, setShowFileUpload] = useState<Boolean>(false);
    const dispatch = useDispatch();

    const fetchPlanSpecificMediaFiles = () => {
        MediaApi.fetchPlanSpecificMediaFiles().then((response) => {
            setPlanSpecificMediaFiles(response);
        });
    };

    useEffect(() => {
        if(performanceReportingEnabledAccess){
            fetchPlanSpecificMediaFiles();
        }
    }, []);

    const renderPlanSpecificMediaFilesTable = () => {
        return (
            <div className="plan-specific-media-files__table-container">
                <table className="plan-specific-media-files__table">
                    <thead>
                        <tr>
                            <th className="plan-specific-media-files__table-header-title">
                                Media Group Name
                            </th>
                            <th className="plan-specific-media-files__table-header-quarter">
                                Quarter
                            </th>
                            <th className="plan-specific-media-files__table-header-year">
                                Year
                            </th>
                            <th className="plan-specific-media-files__table-header-title">
                                Label
                            </th>
                            <th className="plan-specific-media-files__table-header-title">
                                File Name
                            </th>
                            <th className="plan-specific-media-files__table-header-title-link"/>
                            <th className="plan-specific-media-files__table-header-title-link"/>
                        </tr>
                    </thead>
                    <tbody>{ renderPlanSpecificMediaFiles() }</tbody>
                </table>

            </div>
        );
    };

    const renderPlanSpecificMediaFiles = () => {
        return planSpecificMediaFiles!.map(renderPlanSpecificMediaFile);
    };

    const renderPlanSpecificMediaFile = (planSpecificMediaFile: IMediaFile) => {
        return (
            <tr className="plan-specific-media-file__row" key={planSpecificMediaFile.id}>
                <td className="plan-specific-media-file__name" key={planSpecificMediaFile.mediaGroupName}>
                    {planSpecificMediaFile.mediaGroupName}
                </td>
                <td className="plan-specific-media-file__quarter" key={planSpecificMediaFile.quarter}>
                    Q{planSpecificMediaFile.quarter}
                </td>
                <td className="plan-specific-media-file__year" key={planSpecificMediaFile.year}>
                    {planSpecificMediaFile.year}
                </td>
                <td className="plan-specific-media-file__label" key={planSpecificMediaFile.label}>
                    {planSpecificMediaFile.label}
                </td>
                <td className="plan-specific-media-file__imageName" key={planSpecificMediaFile.fileName}>
                    {planSpecificMediaFile.fileName}
                </td>
                <td>
                    <a className="plan-specific-media-file__view"
                       onClick={() =>
                           getDocumentByMediaFileId(planSpecificMediaFile.id!)}>
                        View
                    </a>
                </td>
                <td>
                    <a className="plan-specific-media-file__delete"
                       onClick={() =>
                        deleteMediaFile(planSpecificMediaFile.id!)}>
                        Delete
                    </a>
                </td>
            </tr>
        );
    };

    const hideAddFile = () => {
        setShowFileUpload(false);
    };

    const getDocumentByMediaFileId = (mediaFileId: number) => {
        MediaApi.getDocumentDownload(mediaFileId);
    };

    const deleteMediaFile = (mediaFileId: number) => {
        const processFailure = () => {
            dispatch(setHeaderNotification(
                {message: "The file has failed to delete, please try again",
                    notificationType: NotificationTypes.FAILURE},
                5000));
        };
        const processSuccess = () => {
            dispatch(setHeaderNotification(
                {message: "The file has been successfully deleted",
                    notificationType: NotificationTypes.SUCCESS},
                5000));
            fetchPlanSpecificMediaFiles();
        };

        MediaApi.deleteMediaFile(mediaFileId)
            .then(() => processSuccess())
            .catch(() => processFailure());
    };

    const renderPlanSpecificMediaFileFileUpload = ()=> {
        return(
            showFileUpload ?
                <MediaFileUpload
                    hide={hideAddFile}
                    onUpload={fetchPlanSpecificMediaFiles}
                    isPlanSpecific={true}
                />
            : null
        );
    };

    return performanceReportingEnabledAccess
           ? <div className="new-common-styles plan-specific-media-files-page__container">
                <div className="plan-specific-media-files-page__header">
                    <h1>Plan Specific Media Files</h1>
                </div>
                <div className="plan-specific-media-files-page__table-container">
                    { renderPlanSpecificMediaFilesTable() }
                </div>
                <div className="addMediaFile">
                    <a className="plan-specific-media-file__add-file"
                       onClick={()=>setShowFileUpload(!showFileUpload)}>+ Add File</a>
                </div>
                <div>
                    { renderPlanSpecificMediaFileFileUpload() }
                </div>
            </div>
            : <ErrorComponent />;
};

export default withVerifyAccess(PlanSpecificMediaFilesPage, UserTypeEnum.CONSULTANT);