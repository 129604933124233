import * as React from "react";
import * as ReactMarkdown from "react-markdown";
import {ComponentMode} from "../release-notes/ReleaseNotesPage";

export interface IMarkDownCodeMirrorComponentProps {
    text: string;
    mode: ComponentMode;
    onChange: (newText: string) => void;
}

export const MarkdownCodeMirrorComponent = (props: IMarkDownCodeMirrorComponentProps) => {

    const renderPreviewOrEditFields = () => {
        return props.mode === "preview" ? renderPreviewBox() : renderEditableFields();
    };

    const renderPreviewBox = () => {
        return <ReactMarkdown className="markdown-codemirror-component__preview-markdown"
                           source={props.text}
                           skipHtml={false}
            />;
    };

    const renderEditableFields = () => {
        return <textarea rows={3} className="markdown-codemirror-component__edit-box"
                         data-testid="markdown-codemirror-component__edit-box"
                         autoFocus
                         onChange={(e) => props.onChange(e.target.value)}
                         defaultValue={props.text}/>;
    };

    return renderPreviewOrEditFields();
};
