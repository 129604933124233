import * as React from "react";
import {Map as ImmutableMap} from "immutable";
import {ProductSummary} from "../../../../model/product/ProductSummary";
import {IOddResponse} from "../../../../api/OddIqApi";
import {AssetClassNames} from "../../../research/research-filters/FilterAndSort.menuItems";
import {Link} from "react-router-dom";
import {byName} from "../../../../utils/listUtil";
import {compareDates} from "../../../../utils/dateUtil";
import {ReportComponent} from "../../odd-product-page/Report.component";
import {OperationalDueDiligenceDocument} from "../../../../model/OperationalDueDiligenceDocument.model";
import {useEffect} from "react";
import {isIE, scrollElementToTop, scrollToElement} from "../../../../utils/browserUtil";

export interface IBackstopStrategiesWithExceptionsProps {
    portfolioProductSummaries: ImmutableMap<number, ProductSummary>;
    oddExceptionResponses: IOddResponse[];
    currentQuestionId: number;
    oddDocuments: OperationalDueDiligenceDocument[],
}

export const BackstopStrategiesWithExceptions: React.FunctionComponent<IBackstopStrategiesWithExceptionsProps> = (props) => {
    const responsesForQuestion = props.oddExceptionResponses.filter((response) =>
        response.questionId === props.currentQuestionId);

    function renderOddReportButton(productBackstopId: number) {
        const oddDocuments = props.oddDocuments
            .filter((doc) =>
            doc.products.findIndex((id) => id === productBackstopId) > -1)
            .sort((doc1, doc2) => compareDates(doc1.effectiveDate, doc2.effectiveDate));

        const oddDocument = oddDocuments.length > 0
            ? oddDocuments[0]
                : undefined;

        return oddDocument
        ? <ReportComponent productBackstopId={productBackstopId}
                           oddProductDocument={oddDocument}
                   />
            : <span className="__no-odd-document">No report available</span>;
    }

    function renderProductName(productSummary: ProductSummary) {
        return <Link className="clickable odd-summary__product-link" to={`/products/${productSummary.backstopId}?tab=ODD`}>
            <span className="odd-summary__product-name">{productSummary.name}</span>
        </Link>;
    }

    function renderProductRows() {
        const productSummaries: ProductSummary[] = responsesForQuestion.map((response) => {
                return props.portfolioProductSummaries.get(response.productBackstopId)!;
            }).sort(byName);

        return productSummaries.map((productSummary, index) => {
            return <tr key={index}>
                <td className="__product-name data">{renderProductName(productSummary)}</td>
                <td className="__asset-class">{AssetClassNames[productSummary.assetType]}</td>
                <td className="__odd-report">
                    {renderOddReportButton(productSummary.backstopId)}
                </td>
            </tr>;
        });
    }

    function renderTable() {
        return responsesForQuestion.length > 0
            ? <table className="__strategies-with-exceptions-table">
                <thead>
                <tr>
                    <th className="column-header">Strategies with Exceptions</th>
                    <th className="column-header">Type</th>
                    <th className="__view-report-header"/>
                </tr>
                </thead>
                <tbody>
                    {renderProductRows()}
                </tbody>
            </table>
            : <div className="__no-exceptions">There are no strategies with this exception.</div>;
    }

    useEffect(() => {
        const offset = isIE() ? 133 : 100;
        scrollToElement(".__strategies-with-exceptions-table-container", offset, true);
        scrollElementToTop("#__strategies-outer-container.show");
    }, []);

    return <div className="__strategies-with-exceptions-table-container"
                data-testid="backstop-strategies-with-exceptions">
        {renderTable()}
    </div>;
};