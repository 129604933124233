import * as React from "react";
import {
    BarChart,
    Bar,
    CartesianGrid,
    XAxis,
    YAxis, Cell, LabelList, ReferenceLine, ResponsiveContainer
} from 'recharts';
import {IValueByPeriod} from "../../model/inform/InformSummary";
import {
    formatInViewDate,
    getBarColor,
    getMinValue,
    getRelativePerformanceChartData,
    validRelativePerformanceData
} from "./informUtil";
import {CHART_SOURCE} from "./PerformanceComponent";

interface ICustomTickProps {
    x: number,
    y: number,
    stroke: string,
    payload: any
}

function CustomizedTick(props: ICustomTickProps) {
    const PA = "(p.a.)";
    const {x, y, stroke, payload} = props;

    const render2Lines = (value: string) => {
        return <text x={0} y={0} dy={16}>
            <tspan textAnchor="middle" x="0">
                {value.replace(PA, "")}
            </tspan>
            <tspan textAnchor="middle" x="0" dy="20">
                {PA}
            </tspan>
        </text>;
    };
    const render1Line = (value: string) => {
        return <text x={0} y={0} dy={16}>
            <tspan textAnchor="middle" x="0">
                {value}
            </tspan>
        </text>;
    };


    return (
        <g transform={`translate(${x},${y})`}>
            {payload.value.includes(PA) ? render2Lines(payload.value) : render1Line(payload.value)}
        </g>
    );

}

export interface IRelativePerformanceChartProps {
    relativePerformance: IValueByPeriod[],
    effectiveDate: string,
    informBenchmark: string
}

export const RelativePerformanceChart: React.FunctionComponent<IRelativePerformanceChartProps> = (props: IRelativePerformanceChartProps) => {
    const renderChart = () => {
        const data = getRelativePerformanceChartData(props.relativePerformance);
        const min = getMinValue(data);
        return (
            <div className="relative-performance__chart" data-testid="relative-performance__chart">
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                        height={400}
                        data={data}
                        margin={{top: 50, right: 30, left: 20, bottom: 50,}}
                    >
                        <CartesianGrid stroke="#ccc" vertical={false}/>
                        <XAxis axisLine={false} tickLine={false} dataKey="period"
                            // padding={{left: 10, right: 10}}
                               tick={CustomizedTick}
                        />
                        <YAxis axisLine={false} tickLine={false} padding={{top: 20, bottom: 20}}
                               tickFormatter={value => `${value}%`}
                               domain={[min, "auto"]}/>
                        {/*<Tooltip cursor={false}/>*/}
                        <ReferenceLine y={0} stroke="#000"/>
                        <Bar dataKey="value"
                            // activeBar={{stroke: 'red', strokeWidth: 2, fill: "#5D6D78"}}
                             maxBarSize={40}>
                            {data?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={getBarColor(entry.valueAsNumber)} cursor="pointer"/>
                            ))}
                            <LabelList dataKey="label" position="top"/>
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );

    };

    return (
        <div className="relative-performance__container" data-testid="relative-performance__container">
            <div className="relative-performance__header" data-testid="relative-performance__header">
                <h3>Relative Performance</h3>
                <p className="relative-performance__header-benchmark">Fund performance compared
                    to {props.informBenchmark}</p>
                <div className="relative-performance__header-date-source-container">
                    <div className="relative-performance__header-as-of">as
                        of {formatInViewDate(props.effectiveDate)}</div>
                    <div className="relative-performance__header-as-of">{CHART_SOURCE}</div>
                </div>
            </div>
            {validRelativePerformanceData(props.relativePerformance)
                ? renderChart()
                : <div data-testid="relative-performance__no-data">No Sufficient Data</div>}
        </div>
    );
};