import {Map} from "immutable";
import * as React from "react";
import {connect} from "react-redux";
import {Action, AnyAction, bindActionCreators, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";
import {
    hasClientResearchEnabled,
    hasResearchAccess,
    isEditingPlanPortfolio,
    portfolioProductSummariesSelector,
} from "../../../mainReducerMapSelectors";
import {IManagerResearchModel} from "../../model/manager/IManagerResearchModel";
import {ProductSummary} from "../../model/product/ProductSummary";
import {IAttributeFilter} from "../../model/Research.model";
import {planName} from "../../utils/planUtil";
import {ErrorComponent} from "../base/Error.component";
import {getSuccessData} from "../common/commonStates";
import {
    allFlashReportsSummariesRequestPair,
    allMeetingsNotesRequestPair,
    allProductsRequestPair,
} from "../common/RequesterPairs";
import ManagerCards from "./ManagerCards.component";
import filterActions, {FilterActionTypes} from "./research-filters/filterActions";
import {AssetClassFilter, ManagerSortOption, RatingTypeFilter} from "./research-filters/FilterAndSort.menuItems";
import ResearchFilters from "./research-filters/ResearchFilterAndSort.component";
import {applyFilters, groupAndSortProducts} from "./researchPageUtil";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {NoAccessNotification} from "../base/header/NoAccessNotification";
import {FormattedMessage} from "react-intl";
import {getTranslation} from "../../utils/translationUtil";
import {IApplicationRootState} from "../../../applicationState";

export interface IResearchPropsFromActions {
    actions: {
        clearFilters: () => ThunkAction<void, FilterActionTypes, null, Action<void>>;
    };
}

export interface IResearchPropsFromStore {
    selectedAssetClass: AssetClassFilter;
    selectedRatingType: RatingTypeFilter;
    selectedSortOption: ManagerSortOption;
    selectedAttributes?: IAttributeFilter;
    editingPlanPortfolio: boolean;
    portfolioProductSummaries: Map<number, ProductSummary>;
    hasClientResearchEnabled: boolean;
    hasResearchAccess: boolean;
}

export type ResearchPageProps = IResearchPropsFromActions & IResearchPropsFromStore;

interface IEditingPortfolioHeaderProps {planName: string; }

export const EditingPortfolioHeader: React.FunctionComponent<IEditingPortfolioHeaderProps> = (props) => {
    return <div className="editing-header" data-testid="editing-header">
        <h1>Editing Client Portfolio: {props.planName}</h1>
        <div className="help-text">
            <div>
                To remove strategies: click the product name to expand and select “Remove from Portfolio”.
            </div>
            <div>
                To add strategies: find the strategy detail page using the search box and select “Add to Portfolio”.
            </div>
        </div>
    </div>;
};

export class ResearchPageComponent extends React.Component<ResearchPageProps> {
    public render() {
        if (!this.props.hasClientResearchEnabled && !this.props.hasResearchAccess) {
            return <ErrorComponent/>;
        }

        const managerProducts = this.getGroupedProducts(this.props);
        return (
            <>
                {this.renderNoResearchNotification()}
                <div className="research-page">
                    {this.props.editingPlanPortfolio ? <EditingPortfolioHeader planName={planName()}/> : ""}
                    <ResearchFilters/>
                    {managerProducts.length === 0 ? this.renderMessageContainer() : ""}
                    <ManagerCards data={managerProducts}/>
                </div>
            </>
        );
    }

    private renderNoResearchNotification() {
        return !this.props.hasClientResearchEnabled
            ? <NoAccessNotification message={getTranslation(
                "research.hidden-from-clients",
                "The Managers & Strategies section is currently hidden from client users of this plan.")}/>
            : null;
    }

    private renderMessageContainer() {
        return <div className="no-products-message-container">
            <div className="no-products-message" data-testid="no-products-message">
                <FormattedMessage
                    id="research.no-match"
                    defaultMessage="No strategies match the filters you've selected."
                />
            </div>
            <a className="clear-filters link-16 clickable" onClick={this.props.actions.clearFilters}
               data-testid="clear-filters">
                <FormattedMessage
                    id="research.clear-filters"
                    defaultMessage="Clear Filters"
                />
            </a>
        </div>;
    }

    private getGroupedProducts = (props: ResearchPageProps): IManagerResearchModel[] => {
        const products = applyFilters(
            props.portfolioProductSummaries.valueSeq().toArray(),
            props.selectedAssetClass,
            props.selectedRatingType,
            props.selectedAttributes,
        );

        return groupAndSortProducts(products, props.selectedSortOption);
    };
}

export const mapStateToProps = (state: IApplicationRootState): IResearchPropsFromStore => {
    return {
        ...state.filterAndSortState!,
        editingPlanPortfolio: isEditingPlanPortfolio(state),
        portfolioProductSummaries: getSuccessData(portfolioProductSummariesSelector(state))!,
        hasClientResearchEnabled: hasClientResearchEnabled(state),
        hasResearchAccess: hasResearchAccess(state),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return {
        actions: bindActionCreators({
                ...filterActions,
            },
            dispatch),
    };
};

const connectedComponent =
    connect<IResearchPropsFromStore,
        IResearchPropsFromActions,
        ResearchPageProps>(mapStateToProps, mapDispatchToProps)(ResearchPageComponent);

export default multipleApiRequesterWrapper(
    connectedComponent,
    [
        allMeetingsNotesRequestPair,
        allFlashReportsSummariesRequestPair,
        allProductsRequestPair(),
    ],
);
