import * as React from "react";
import {IInformPerformance} from "../../model/inform/InformPerformance";
import {formatXAxisPeriodToYear, getCumulativeAbsoluteReturnChartData} from "./informUtil";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";

export const PerformanceCumulativeAbsoluteChart: React.FunctionComponent<IInformPerformance> = (props: IInformPerformance) => {
    const data = getCumulativeAbsoluteReturnChartData(props.productCumulative, props.benchCumulative);
    return <div className="inform-performance__cumulative-absolute-chart"
                data-testid="inform-performance__cumulative-absolute-chart">
        <div className={"cumulative-absolute__returns-errors-container"}>
            <div className="cumulative-absolute__excess-return" data-testid="cumulative-absolute__excess-return">
                <h5>Excess Return</h5>
                <div className="cumulative-absolute__returns-errors-body">{props.annualizedExcessReturns}</div>
                <div className="cumulative-absolute__returns-errors-footer">
                    {props.performanceAnalyticsPeriod} Annualized
                </div>
            </div>
            <div className="cumulative-absolute__tracking-error" data-testid="cumulative-absolute__tracking-error">
                <h5>Tracking Error</h5>
                <div className="cumulative-absolute__returns-errors-body">{props.annualizedTrackingError}</div>
                <div className="cumulative-absolute__returns-errors-footer">
                    {props.performanceAnalyticsPeriod} Annualized
                </div>
            </div>
        </div>
        <div className="cumulative-absolute__chart-title" data-testid="cumulative-absolute__chart-title">
            <p>
                The {props.performanceAnalyticsPeriod} max drawdown of the strategy is {props.maxDrawdown} while
                the benchmark’s max drawdown is {props.maxDrawdownBenchmark}.
            </p>
        </div>
        <div className="cumulative-absolute__chart-container" data-testid="cumulative-absolute__chart-container">
            <ResponsiveContainer width="100%" height={315}>
                <LineChart
                    height={315}
                    data={data}
                    margin={{top: 15, right: 0, left: 0, bottom: 0,}}
                >
                    <CartesianGrid stroke="#ccc" vertical={false}/>
                    <XAxis axisLine={false} tickLine={false} dataKey="period"
                           interval={11}
                           tickFormatter={formatXAxisPeriodToYear}
                           className={"axis-label"}
                    />
                    <YAxis axisLine={false} tickLine={false} domain={['auto', 'auto']}
                           padding={{top: 0, bottom: 20}}
                           dataKey={"product"} type={"number"}
                           tickFormatter={value => `${value}%`}
                           className={"axis-label"}
                    />
                    <Tooltip formatter={(value, name) => [`${value}%`, name]}/>
                    <Legend iconType={"circle"} wrapperStyle={{height: 30, bottom: -13}}/>
                    <ReferenceLine y={0}/>
                    <Line dataKey="product" stroke="#A70070" strokeWidth={2} dot={false}
                          name={`Product: ${props.strategyName}`}/>
                    {(props.benchCumulative && props.benchCumulative.length > 0) &&
                        <Line dataKey="benchmark" stroke="#0084BB" strokeWidth={2} dot={false}
                              name={`Benchmark: ${props.inFormBenchmark}`}/>
                    }
                </LineChart>
            </ResponsiveContainer>
        </div>
    </div>;
};