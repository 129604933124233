import {default as adminReducer} from "./main/components/admin/AdminReducer";
import {default as headerReducer} from "./main/components/base/header/HeaderReducer";
import {
    default as allCapitalMarketAssumptionsReducer
} from "./main/components/capital-market-assumptions/AllCapitalMarketAssumptionsReducer";
import {default as clientReducer,} from "./main/components/client/ClientReducer";
import {default as feedbackPageReducer,} from "./main/components/feedback/feedbackPageReducer";
import {default as allFlashReportsReducer} from "./main/components/flash-report/AllFlashReportsReducer";
import {default as flashReportReducer,} from "./main/components/flash-report/flashReportReducer";
import {default as holdingsReducer} from "./main/components/investments/holdings/HoldingsReducer";
import {default as allManagersReducer} from "./main/components/manager/AllManagersReducer";
import {default as allMeetingNotesReducer} from "./main/components/meeting-note/AllMeetingNotesReducer";
import {default as meetingNoteReducer,} from "./main/components/meeting-note/meetingNoteReducer";
import {default as oddIQReducer} from "./main/components/odd-iq/OddIqReducer";
import {default as allProductsReducer} from "./main/components/product/AllProductsReducer";
import {default as productReducer,} from "./main/components/product/productReducer";
import {default as portfolioAdminReducer} from "./main/components/research/edit-portfolio/editPortfolioReducer";
import {default as productStoreReducer} from "./main/components/research/productStoreReducer";
import {default as filterReducer} from "./main/components/research/research-filters/filterReducer";
import {default as strategyUpdatesPageReducer} from "./main/components/research/strategy-update/strategyUpdatesReducer";
import {default as planSettingsReducer} from "./main/components/root/PlanSettings.reducer";
import {default as SessionReducer} from "./main/components/session/SessionReducer";
import {default as sharedDocumentsReducer} from "./main/components/shared-documents/SharedDocuments.reducer";
import {default as ThoughtLeadershipReducer} from "./main/components/thought-leadership/ThoughtLeadershipReducer";
import {default as powerBiReportingReducer,} from "./main/components/power-bi-reporting/PowerBiReporting.reducer";
import {default as calendarReducer,} from "./main/components/calendar/Calendar.reducer";
import {default as cprPortfolioReducer} from "./main/components/home-page/CprPortfolioReducer";
import {routerReducer} from "./reduxHistoryContext";
import {combineReducers} from "redux";

const appReducerMap = {
    allManagersStore: allManagersReducer,
    allProductsStore: allProductsReducer,
    allMeetingNotesStore: allMeetingNotesReducer,
    allFlashReportsStore: allFlashReportsReducer,
    allCapitalMarketAssumptionsStore: allCapitalMarketAssumptionsReducer,
    flashReportState: flashReportReducer,
    meetingNoteRootState: meetingNoteReducer,
    productPageState: productReducer,
    feedbackState: feedbackPageReducer,
    productStore: productStoreReducer,
    headerRootState: headerReducer,
    sharedDocumentsRootState: sharedDocumentsReducer,
    session: SessionReducer,
    filterAndSortState: filterReducer,
    strategyUpdatesPageState: strategyUpdatesPageReducer,
    portfolioAdminState: portfolioAdminReducer,
    adminState: adminReducer,
    currentClient: clientReducer,
    planSettings: planSettingsReducer,
    thoughtLeadership: ThoughtLeadershipReducer,
    holdingsState: holdingsReducer,
    oddIQStore: oddIQReducer,
    powerBiReportingStore: powerBiReportingReducer,
    calendarStore: calendarReducer,
    cprPortfolioStore: cprPortfolioReducer
};

const reducerMapWithRouter = () => {
    return {...appReducerMap, router: routerReducer};
};

export const createRootReducer = () => {
    const reducer = combineReducers(reducerMapWithRouter());
    return reducer;
};

export const createRootReducerWithoutRouter = () => {
    return combineReducers(appReducerMap);
};

