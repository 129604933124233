import {ShallowWrapper} from "enzyme";
import {FormattedMessage} from "react-intl";

declare module "enzyme" {
    // eslint-disable-next-line @typescript-eslint/interface-name-prefix
    export interface ShallowWrapper {
        formattedText(): string;
        formattedTextById(id: string): string;
        formattedTextInProp(prop: string): string;
        getColumn(column: number): ShallowWrapper;
        getTableHeaderText(column: number): string;
        getRowAndColumn(row: number, column: number): ShallowWrapper;
        getRowAndColumnText(row: number, column: number): string;
    }
}

ShallowWrapper.prototype.getColumn = function(column: number) {
    return this.find("tr").find("td").at(column);
};

ShallowWrapper.prototype.getRowAndColumn = function(row: number, column: number) {
    return this.find("tbody tr").at(row).find("td").at(column);
};

ShallowWrapper.prototype.getRowAndColumnText = function(row: number, column: number) {
    return this.getRowAndColumn(row, column).text();
};

ShallowWrapper.prototype.getTableHeaderText = function(column: number) {
    return this.find("th").at(column).text();
};

ShallowWrapper.prototype.formattedText = function(): string {
    return this.find(FormattedMessage).props().defaultMessage;
};

ShallowWrapper.prototype.formattedTextById = function(id: string): string {
    return this.find(FormattedMessage).findWhere((subject: ShallowWrapper) => {
        return (subject.props() as any).id === id;
    }).props().defaultMessage;
};

ShallowWrapper.prototype.formattedTextInProp = function(prop: string): string {
    return this.props()[prop].props.defaultMessage;
};
