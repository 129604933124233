import "moment";
import * as React from "react";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";
import {navigateTo} from "../../../navigateTo";
import {clearCurrentNavigationTab, IHeaderAction} from "./header/HeaderActions";
import moment = require("moment");

export interface IFooterPropsFromActions {
    actions: {
        clearCurrentNavigationTab: () => ThunkAction<void, {}, null, IHeaderAction>;
    };
}

export const Footer: React.FunctionComponent<IFooterPropsFromActions> = (props) => {

    return <div className="main-footer" data-testid="main-footer">
        <div className="main-footer-top-row">
            <Link
                className="feedback-link old-anchor"
                to="/feedback"
                tabIndex={0}
                onClick={props.actions.clearCurrentNavigationTab}>
                    <FormattedMessage id="footer.send-feedback" defaultMessage="Send us your feedback" />
            </Link>
            <div className="main-footer-top-row-separator old-anchor">|</div>
            <Link
                className="release-notes-link"
                tabIndex={0}
                to="/release-notes"
                onClick={() => navigateTo("/release-notes")}>
                <FormattedMessage id="footer.release-notes" defaultMessage="Release Notes" />
            </Link>
        </div>
        <div className="main-footer-bottom-row">
            <div id="copyright"><span className="fal fa-copyright"/> Aon {moment().year()}</div>
            <div className="main-footer-bottom-row-separator">|</div>
            <Link
                className="disclaimer-link old-anchor"
                to="/disclaimer"
                tabIndex={0}
                onClick={props.actions.clearCurrentNavigationTab}
            >
                <FormattedMessage id="footer.disclaimer" defaultMessage="Disclaimer" />
            </Link>
            <div className="main-footer-bottom-row-separator old-anchor">|</div>
            {/*Begin OneTrust Footer Link for US site visitors*/}
            <a className="optanon-toggle-display USFooterLink" tabIndex={0}>
                <FormattedMessage id="footer.do-not-sell-share-personal-information"
                                  defaultMessage="Do Not Sell or Share My Personal Information"/>
            </a>
            {/*End OneTrust Footer Link for US site visitors*/}
            &nbsp;
            {/*Begin OneTrust Footer Link for EU Site visitors */}
            <a className="optanon-toggle-display EUFooterLink">Cookie Preferences</a>
            {/*End OneTrust Footer Link for EU Site visitors*/}
        </div>
    </div>;
};

export function mapDispatchToProps(dispatch: Dispatch<AnyAction>): IFooterPropsFromActions {
    return {
        actions: bindActionCreators({clearCurrentNavigationTab}, dispatch),
    };
}

const connectedComponent = connect<{}, IFooterPropsFromActions>(null, mapDispatchToProps)(Footer);
export default connectedComponent;
