import moment = require("moment");
import {ISelectValue} from "../components/common/Select.component";
import {getTranslation} from "./translationUtil";

export interface IDated {
    date: Date | null;
}

export enum Quarter {
    Q1 = "Q1",
    Q2 = "Q2",
    Q3 = "Q3",
    Q4 = "Q4",
}

export interface IQuarterAndYear {
    quarter: Quarter;
    year: number;
}

export const getRecentYears = () => {
    const date = new Date();
    const yearCount = date.getFullYear() - 1990 + 1;
    return Array
        .from(Array(yearCount).keys())
        .map((_, idx) => idx + 1990)
        .reverse()
        .map((year) => ({name: year.toString(), id: year}));
};

export const getQuarters = () => {
    return Object.keys(Quarter)
        .map((quarter) => ({name: quarter, id: null}));
};

export function byDate(a: IDated, b: IDated) {
    return compareDates(a.date, b.date);
}

export function compareDates(date1: Date | null, date2: Date | null): number {
    if (date1 === null && date2 === null) {
        return 0;
    } else if (date1 === null) {
        return 1;
    } else if (date2 === null) {
        return -1;
    } else {
        return date2.getTime() - date1.getTime();
    }
}

export function isDateBetween(date: Date | null, startDate: Date | null, endDate: Date | null) {
    return compareDates(startDate, date) > 0 && compareDates(date, endDate) > 0;
}

export function compareQuarterAndYear(a: IQuarterAndYear, b: IQuarterAndYear): number {
    if (a === b) {
        return 0;
    } else if ((a.year - b.year) > 0 || (a.year === b.year && (a.quarter.localeCompare(b.quarter)) > 0)) {
        return 1;
    }
    return -1;
}

export function compareQuarterAndYearString(a: {quarter: string, year: number}, b: {quarter: string, year: number})
    : number {
    if (a === b) {
        return 0;
    } else if ((a.year - b.year) > 0 || (a.year === b.year && (a.quarter.localeCompare(b.quarter)) > 0)) {
        return 1;
    }
    return -1;
}

export function parseDate(dateString: string): Date {
    return moment(dateString, "YYYY-MM-DD").toDate();
}

export function parseShortDate(dateString: string): Date {
    const dateFormat = "DD MMM YYYY";
    return moment(dateString, dateFormat).toDate();
}

export function formatLongDate(date: Date | string): string {
    return moment(date).format("DD MMMM YYYY");
}

export function formatDateAndTime(date: Date | string): string {
    return moment(date).format("MMM DD YYYY   h:mm A");
}

export function formatDateAndTimeCustom(date: Date | string | undefined, formatString: string): string {
    return date ? moment(date).format(formatString) : "";
}

export function formatNumericDate(date: Date | string) {
    return moment(date).format("YYYY-MM-DD");
}

export const shortDateFormat = "DD MMM YYYY";
export const reactDatePickerShortDateFormat = "dd MMM yyyy";

export function formatShortDate(date: Date | string): string {
    return translateShortDate(moment(date).format(shortDateFormat));
}

const translateShortDate = (shortDate: string) => {
    const date = shortDate.substring(0, 2);
    const month = getTranslation(`date.month_${shortDate.substring(3, 6).toLowerCase()}`, shortDate.substring(3, 6));
    const year = shortDate.substring(7, 12);
    return date + ' ' + month + ' ' + year;
};

export function getDateByQuarter(quarter: Quarter, year: number): Date {
    // month is zero based
    const index = Object.keys(Quarter).indexOf(quarter) + 1;
    const month = (index * 3) - 3;
    return new Date(year, month, 1, 0, 0, 0);
}

export function getQuarterAndYearByDate(date: Date): IQuarterAndYear {
    const year = date.getFullYear();
    const month = date.getMonth();
    const quarter = (month < 3) ? Quarter.Q1 : (month < 6) ? Quarter.Q2 : (month < 9) ? Quarter.Q3 : Quarter.Q4;
    return { quarter, year };
}

export function getQuarterAndYearString(quarterAndYear: IQuarterAndYear): string {
    return `${quarterAndYear.year} ${quarterAndYear.quarter}`;
}

export function formatDateAsQuarterAndYear(date: Date) {
    const quarterAndYear = getQuarterAndYearByDate(date);
    return `${quarterAndYear.quarter} ${quarterAndYear.year}`;
}

export function formatQuarterAndYear(quarterAndYear: IQuarterAndYear): string {
    return `${quarterAndYear.quarter} ${quarterAndYear.year}`;
}

export function getMonthAndYearString(date: Date): string {
    const monthName = date.toLocaleString("en-GB", {month: "long"});
    return `${monthName} ${date.getFullYear()}`;
}

export function getShortMonthAndYearString(date: Date): string {
    const monthName = date.toLocaleString("en-GB", {month: "short"});
    return `${monthName} ${date.getFullYear()}`;
}

export function getMonthName(index: number) {
    return moment(new Date(2000, index)).format("MMMM");
}

export enum QuarterFilterItems {
    ALL = "All Quarters",
    Q1 = "Q1",
    Q2 = "Q2",
    Q3 = "Q3",
    Q4 = "Q4",
}

export function getEndOfMonthDate(year: number, month: number): Date {
    const nextDay = new Date(year, month + 1);
    nextDay.setDate(nextDay.getDate() - 1);
    return nextDay;
}

export function formatDate(date: Date, format: string): string {
    return moment(date).format(format);
}

export function getQuartersSelectValues(): ISelectValue[] {
    return Object.keys(QuarterFilterItems)
        .map((key: keyof typeof QuarterFilterItems) => ({
            id: QuarterFilterItems[key],
            name: QuarterFilterItems[key],
        }));
}

export function getCurrentYear(): number {
    return new Date().getFullYear();
}
