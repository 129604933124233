import * as React from "react";
import {Link} from "react-router-dom";

class TrackedLinkProps {
    public to: string;
    public className: string;
    public gaCategory: string;
    public gaAction: string;
    public gaLabel: string;
}

export class TrackedLink extends React.Component<TrackedLinkProps, string> {
    public render() {
        return <Link to={this.props.to} className={this.props.className} data-testid="tracked-link">
            {this.props.children}
        </Link>;
    }
}
