import {ApiBase} from "./ApiBase";
import {AxiosResponse} from "axios";
import {IInformProduct} from "../model/inform/InformProduct";


export interface IRangeValue {
    value: string,
    date: Date
}

export class InFormReportingApi {
    public static fetchInformProduct(backstopId: number): Promise<IInformProduct> {
        return ApiBase.processGetUnwrapped(`/inform-product/${backstopId}`,
            (response: AxiosResponse) => {
                return response.data;
            });
    }
}