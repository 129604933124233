import * as React from "react";
import {createIntl, createIntlCache, RawIntlProvider} from "react-intl";
import {connect} from "react-redux";
import {planLanguageId} from "../../../mainReducerMapSelectors";
import {getIntlProps} from "../common/intlUtils";
import {setIntl} from "../../utils/translationUtil";
import {IApplicationRootState} from "../../../applicationState";

export interface IInitializeIntlComponentProps {
    planLanguageId: number;
}

export const InitializeIntlComponent: React.FunctionComponent<IInitializeIntlComponentProps> = (props) => {
    const cache = createIntlCache();
    const intlProps = getIntlProps(props.planLanguageId);
    const intl = createIntl({...intlProps}, cache);
    setIntl(intl);
    return <RawIntlProvider value={intl}>{props.children}</RawIntlProvider>;
};

export const mapStateToProps = (state: IApplicationRootState): IInitializeIntlComponentProps => {
    return {
        planLanguageId: planLanguageId(state),
    };
};

const connectedComponent = connect<IInitializeIntlComponentProps, {}>
(mapStateToProps)(InitializeIntlComponent);

export default connectedComponent;
