import * as React from "react";
import {useState} from "react";
import {connect} from "react-redux";
import {
    capitalMarketAssumptionsSelector, marketBackgroundsSelector,
    mediumTermViewsSelector,
    quarterlyInvestmentOutlookSelector,
} from "../../../mainReducerMapSelectors";
import {ICapitalMarketAssumption} from "../../model/thought-leadership/CapitalMarketAssumption";
import {IMediumTermView} from "../../model/thought-leadership/MediumTermView.model";
import {IMarketOutlookDocument} from "../../model/thought-leadership/MarketOutlookDocument.model";
import {byDate, formatDateAsQuarterAndYear, getQuarterAndYearByDate, QuarterFilterItems} from "../../utils/dateUtil";
import {download, getDocumentType, MarketOutlook} from "../../utils/marketOutlookUtils";
import {getSuccessData} from "../common/commonStates";
import {
    capitalMarketAssumptionsRequestPair, marketBackgroundsRequestPair,
    mediumTermViewsRequestPair,
    quarterlyInvestmentOutlookRequestPair,
} from "../common/RequesterPairs";
import {MarketOutlooksFilter} from "./MarketOutlooksFilter.component";
import {getYears} from "./MarketOutlookUtils";
import {FormattedMessage} from "react-intl";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {IApplicationRootState} from "../../../applicationState";

export interface IMarketOutlookPagePropsFromStore {
    capitalMarketAssumptionsState: ICapitalMarketAssumption[];
    mediumTermViewsState: IMediumTermView[];
    quarterlyInvestmentOutlookState: IMarketOutlookDocument[];
    marketBackgroundsState: IMarketOutlookDocument[];
}

export interface IMarketOutlookFilterSelections {
    yearSelection: string;
    quarterSelection: string;
    typeSelection: string;
}

export enum MarketOutlookFilterByOptions {
    BY_YEAR = "by_year",
    BY_QUARTER = "by_quarter",
    BY_TYPE = "by_type",
}

export const ALL_YEARS = "All Years";
export const ALL_TYPES = "All Document Types";

const marketOutlooksIntro = <p className="new-market-outlooks__description large"
                               data-testid="new-market-outlooks__description">
    <FormattedMessage
        id="market-outlooks.description"
        defaultMessage={
            "Aon’s Market Outlook documents are created by our Global Asset Allocation Team. <b>Medium Term Views</b> represent " +
            "our outlooks on capital markets and economies over the next several years, constructed based on our framework of " +
            "analyzing fundamental, valuation and near-term drivers of capital markets. <b>Capital Market Assumptions</b> " +
            "represent the long-term capital market outlook based on data from the end of the previous quarter. The " +
            "<b>Quarterly Investment Outlook</b> covers our quarterly round-up of topical investment issues and market views. " +
            "The <b>Market Background</b> provides a summary of market movements and other key developments over the previous month or quarter."}
        values={{
            b: (...chunks: string[]) => <b>{chunks}</b>,
        }}
    />
</p>;

export const MarketOutlookPage: React.FunctionComponent<IMarketOutlookPagePropsFromStore> = (props) => {
    const allDocuments: MarketOutlook[] = [
            ...props.quarterlyInvestmentOutlookState,
            ...props.mediumTermViewsState,
            ...props.capitalMarketAssumptionsState,
            ...props.marketBackgroundsState,
        ];

    const [selectedYear, setSelectedYear] = useState<string>(ALL_YEARS);
    const [selectedType, setSelectedType] = useState<string>(ALL_TYPES);
    const [selectedQuarter, setSelectedQuarter] = useState<QuarterFilterItems>(QuarterFilterItems.ALL);

    const selections: IMarketOutlookFilterSelections = {
        yearSelection: selectedYear,
        quarterSelection: selectedQuarter,
        typeSelection: selectedType,
    };

    function handleFilterChange(event: any, id: string) {
        switch (id as MarketOutlookFilterByOptions) {
            case MarketOutlookFilterByOptions.BY_YEAR:
                setSelectedYear(event.target.value);
                return;
            case MarketOutlookFilterByOptions.BY_QUARTER:
                setSelectedQuarter(event.target.value);
                return;
            case MarketOutlookFilterByOptions.BY_TYPE:
                setSelectedType(event.target.value);
                return;
        }
    }

    function handleClearFilters() {
        setSelectedType(ALL_TYPES);
        setSelectedYear(ALL_YEARS);
        setSelectedQuarter(QuarterFilterItems.ALL);
    }

    function filterDocuments(document: MarketOutlook) {
        return (selectedYear === "All Years" || document.date.getFullYear() === parseInt(selectedYear, 10))
            && (selectedQuarter === QuarterFilterItems.ALL
                || getQuarterAndYearByDate(document.date).quarter.toString() === selectedQuarter.toString())
            && (selectedType === "All Document Types" || getDocumentType(document) === selectedType);
    }

    function renderDocumentList() {
        return <div className="market-outlooks-page__document-list">
            {renderDocumentsByYear(allDocuments.filter(filterDocuments))}
        </div>;
    }

    function renderDocumentsByYear(documents: MarketOutlook[]) {
        const years = getYears(documents);
        return <div>
            {years.map((year) => renderYearAndDocuments(year, documents))}
        </div>;
    }

    function renderYearAndDocuments(year: string, documents: MarketOutlook[]) {
        return <div className="market-outlooks__section-content" key={year}>
            <div className="market-outlooks__left">
                <span className="date-splash-big market-outlooks__year"
                      data-testid="market-outlooks__year">{year}</span>
            </div>
            <div className="">
                {renderDocumentsForYear(year, documents)}
            </div>
        </div>;
    }

    function renderDocumentsForYear(year: string, documents: MarketOutlook[]) {
        const docsForYearAndQuarter = documents.filter((document) => {
            return document.date.getFullYear() === Number.parseInt(year, 10);
        })
            .sort(byDate);

        return <div className="no-bullets">
            <ul className="document-list" >{docsForYearAndQuarter.map(renderDocument)}</ul>
        </div>;
    }

    function renderDocument(document: MarketOutlook,
                            index: number,
                            documents: MarketOutlook[]) {
        return <li key={index}>
            <div className="document-list__document market-outlooks-document-list__document">
                <div key={document.title}
                     className="document-list__document__info__title clickable-document-title
                                                document-title-16"
                     data-testid="document-list__document__info__title"
                     onClick={() => download(document)}>
                    {document.title}
                </div>
                <div className="document-list__document-date date-12" data-testid="document-list__document-date">
                    {formatDateAsQuarterAndYear(document.date)}
                </div>
            </div>
            {index === documents.length - 1 ? "" : renderSeparator()}
        </li>;
    }

    function renderSeparator() {
        return <div className="document-list__document-separator">
            <div className="spacer-dash--small"/>
        </div>;
    }

    return <div className="new-common-styles page-container">
        <h1 className="new-market-outlooks__header">
            <FormattedMessage id="market-outlooks.title" defaultMessage="Market Outlooks" />
        </h1>
        {marketOutlooksIntro}
        <MarketOutlooksFilter documents={allDocuments}
                              handleFilterChange={handleFilterChange}
                              handleClearFilters={handleClearFilters}
                              selections={selections}/>
        {renderDocumentList()}
    </div>;
};

export const mapStateToProps = (state: IApplicationRootState): IMarketOutlookPagePropsFromStore => {
    return {
        capitalMarketAssumptionsState: getSuccessData(capitalMarketAssumptionsSelector(state))!.valueSeq().toArray(),
        mediumTermViewsState: getSuccessData(mediumTermViewsSelector(state))!,
        quarterlyInvestmentOutlookState: getSuccessData(quarterlyInvestmentOutlookSelector(state))!,
        marketBackgroundsState: getSuccessData(marketBackgroundsSelector(state))!,
    };
};

const connectedMarketOutlookPage = connect(mapStateToProps)(MarketOutlookPage);

export default multipleApiRequesterWrapper(
    connectedMarketOutlookPage,
    [
        capitalMarketAssumptionsRequestPair,
        mediumTermViewsRequestPair,
        quarterlyInvestmentOutlookRequestPair,
        marketBackgroundsRequestPair,
    ],
);
