import {getItemFromLocalStorage, putItemInLocalStorage, removeItemFromLocalStorage} from "../api/BrowserWrapper";
import {SessionData} from "../components/session/SessionData.model";
import {IPlanInfo} from "../model/ClientsAndPlans.model";
import {ConsultantLevelList, doesUserHaveAccess} from "../model/UserDetails.model";
import {throwErrorOnNullOrUndefined} from "./errorUtil";
import {UserTypeEnum} from "../model/UserInfo.model";
import {capitalizeFirstLetter} from "./commonUtil";
import {byName} from "./listUtil";
import {MAX_SECONDS_SINCE_LOGOUT} from "./envUtil";

const SESSION_DATA_KEY = "SessionData";
export const LOGGING_OUT_TIMESTAMP_KEY = "LoggingOutTimestamp";
export const AON_TRUST_PLAN = "Aon Collective Investment Trust";
export const EMPOWER_RESULTS_PLAN = "Empower Results";

export const setUserLoggingOut = () => {
    putItemInLocalStorage(LOGGING_OUT_TIMESTAMP_KEY, Date.now());
};

export const removeUserLoggingOut = () => {
    removeItemFromLocalStorage(LOGGING_OUT_TIMESTAMP_KEY);
};

export const getUserLoggingOut = (): boolean => {
    const loggingOutTimestamp = getItemFromLocalStorage(LOGGING_OUT_TIMESTAMP_KEY);
    // console.error("=============> loggingOut: ", loggingOutTimestamp);
    if (!loggingOutTimestamp) {
        return false;
    }

    const timeSinceLogout = Date.now() - loggingOutTimestamp;
    // console.error("=============> timeSinceLogout: ", timeSinceLogout);
    if (timeSinceLogout < MAX_SECONDS_SINCE_LOGOUT * 1000) {
        return true;
    }
    // console.error("=============> removing UserLoggingOut: exceeded time: ", timeSinceLogout);
    removeUserLoggingOut();
    return false;
};

export const getSessionData = (): SessionData | null => {
    const dataString = getItemFromLocalStorage(SESSION_DATA_KEY);
    return JSON.parse(dataString);
};

export const storeSessionData = (data: SessionData) => {
    const dataString = JSON.stringify(data);
    putItemInLocalStorage(SESSION_DATA_KEY, dataString);
};

export const removeSessionData = () => {
    removeItemFromLocalStorage(SESSION_DATA_KEY);
};

export const updateCurrentPlan = (plan: IPlanInfo) => {
    storeSessionData({...throwErrorOnNullOrUndefined(getSessionData()),
                        ...{currentPlan: plan}});
};

// export const updateLogoutTimestamp = () => {
//     storeSessionData({...throwErrorOnNullOrUndefined(getSessionData()),
//         ...{logoutTimestamp: Date.now()}});
// };
//
// export const getTimeSinceLogout = (): number | undefined => {
//     const sessionData = getSessionData();
//     return sessionData
//     ? sessionData.logoutTimestamp
//         ? Date.now() - sessionData.logoutTimestamp
//             : undefined
//         : undefined;
// };

export const hasConsultantLevelAccess = (): boolean =>  {
    const sessionData = getSessionData();
    return !!sessionData && doesUserHaveAccess(sessionData.userType, ConsultantLevelList);
};

export const getUserType = (): UserTypeEnum | undefined => {
    const sessionData = getSessionData();
    return sessionData ? sessionData.userType : undefined;
};

export const getUserTypeText = (): string => {
    const userType = UserTypeEnum[getUserType() as keyof typeof UserTypeEnum];
    return userType ? capitalizeFirstLetter(userType) : "";
};

export const isClientUser = (): boolean => {
    const sessionData = getSessionData();
    return !!sessionData &&
        (sessionData.userType === UserTypeEnum.CLIENT || sessionData.userType === UserTypeEnum.ATC_ADMIN);
};

export const isAdminUser = (): boolean => {
    const sessionData = getSessionData();
    return !!sessionData && sessionData.userType === UserTypeEnum.ADMIN;
};

export const isThirdPartyUser = (): boolean => {
    const sessionData = getSessionData();
    return !!sessionData && sessionData.userType === UserTypeEnum.THIRD_PARTY;
};

export const isAtcAdminUser = (): boolean => {
    const sessionData = getSessionData();
    return !!sessionData && sessionData.userType === UserTypeEnum.ATC_ADMIN;
};

const listEmpowerResultsFirst = (plans: IPlanInfo[]): IPlanInfo[] => {
    const empowerResultPlan = plans.find((it) => it.name === EMPOWER_RESULTS_PLAN);
    if (empowerResultPlan) {
        return [empowerResultPlan].concat(plans.filter((it) => it.id !== empowerResultPlan.id));
    }
    return plans;
};

const listAonCollectiveInvestmentTrustLast = (plans: IPlanInfo[]): IPlanInfo[] => {
    const aonTrustPlan = plans.find((it) => it.name === AON_TRUST_PLAN);
    if (aonTrustPlan) {
        const nonAonTrustPlans = plans.filter((it) => it.id !== aonTrustPlan.id);
        return nonAonTrustPlans.concat(aonTrustPlan);
    }
    return plans;
};

export const orderPlans = (plans: IPlanInfo[]): IPlanInfo[] => {
    return listEmpowerResultsFirst(listAonCollectiveInvestmentTrustLast(plans.sort(byName)));
};
