const frTranslation = require("../../../translations/fr-FR.json");

export enum LANG {
    ENGLISH = 1,
    FRENCH,
    GERMAN,
}

export enum LANG_NAME {
    ENGLISH = "English",
    FRENCH = "French",
    GERMAN = "German",
}

interface IIntlProps {
    locale: string;
    messages: any;
}

export const getIntlProps = (langId: number): IIntlProps => {
    return {locale: getLangCode(langId),
    messages: getLangMessages(langId)};
};

export const getLangCode = (langId: number) => {
    switch (langId) {
        case(LANG.FRENCH):
            return "fr-FR";
        case(LANG.GERMAN):
            return "de-DE";
        default:
            return "en";
    }
};

export const getLangMessages = (langId: number) => {
    switch (langId) {
        case(LANG.FRENCH):
            return frTranslation;
        case(LANG.GERMAN):
            return {};
        default:
            return {};
    }
};
