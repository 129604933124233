import {Map} from "immutable";
import * as React from "react";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import {
    isEditingPlanPortfolio,
    planCountryName,
    portfolioProductSummariesSelector,
} from "../../../../mainReducerMapSelectors";
import {AssetClassOptions} from "../../../model/AssetClassOptions";
import {ProductSummary} from "../../../model/product/ProductSummary";
import {IAttributeFilter} from "../../../model/Research.model";
import {defaultButtonStyle, prominentButtonStyle} from "../../common/buttonStyles";
import {RaisedButton} from "../../common/RaisedButton";
import {hasConsultantLevelAccess} from "../../../utils/sessionUtil";
import {getSuccessData} from "../../common/commonStates";
import {SelectComponent} from "../../common/Select.component";
import adminActions from "../edit-portfolio/editPortfolioActions";
import RatingIconComponent from "../icons/RatingIcon.component";
import AttributeFiltersComponent from "./AttributeFiltersComponent";
import filterActions from "./filterActions";
import {
    AssetClass,
    AssetClassFilter,
    assetClassFilterItems,
    managerSearchItems,
    ManagerSortOption,
    ratingFilterItems,
    RatingTypeFilter,
} from "./FilterAndSort.menuItems";
import {IApplicationRootState} from "../../../../applicationState";

export interface IResearchFilterPropsFromActions {
    actions: {
        updateSelectedAssetClass: (value: AssetClassFilter) => any;
        updateSelectedRating: (value: RatingTypeFilter) => any;
        updateSelectedSortOption: (value: ManagerSortOption) => any;
        clearFilters: () => any;
        beginEditPortfolio: () => any;
        endEditPortfolio: () => void;
        updateSelectedAttributes: (value?: IAttributeFilter) => any;
    };
}

export interface IResearchFilterPropsFromStore {
    selectedAssetClass: AssetClassFilter;
    selectedRating: RatingTypeFilter;
    selectedSortOption: ManagerSortOption;
    editingPlanPortfolio: boolean;
    selectedAttributes?: IAttributeFilter;
    portfolioProductSummaries: Map<number, ProductSummary>;
    planCountryName: string;
}

export type ResearchFiltersProps =
    IResearchFilterPropsFromActions &
    IResearchFilterPropsFromStore;

export class ResearchFilterAndSortComponent extends React.Component<ResearchFiltersProps> {

    constructor(props: ResearchFiltersProps) {
        super(props);
    }

    public render() {

        return <div>
            <div className="research-filter-and-sort" data-testid="research-filter-and-sort">
                <div className="research-filter-left">
                    <div className="asset-and-rating-filters">
                        <div className="research-filter-label">
                            <FormattedMessage id="research.filter-label"
                             defaultMessage="Filter:" />
                        </div>
                        <div className="research-filter-spacer"/>
                        {this.renderAssetFilterMenu()}
                        <div className="research-filter-spacer"/>
                        {this.renderRatingFilterMenu()}
                        {this.renderClearFiltersLink()}
                        <div className="research-filter__right-spacer"/>
                    </div>
                </div>
                <div className="research-sort-right">
                    <div>
                        <FormattedMessage id="research.sort-by-label"
                            defaultMessage="Sort by:" />
                    </div>
                    <div className="sort-spacer"/>
                    {this.renderManagerSortMenu()}
                </div>
                {this.renderEditControlButton()}
            </div>
            <AttributeFiltersComponent/>
        </div>;
    }

    private renderAssetFilterMenu() {
        return <SelectComponent
            id={"filter-menu__asset-class"}
            values={assetClassFilterItems.filter((it) => this.hasAssetAccess(it.id as AssetClassFilter))}
            selected={this.props.selectedAssetClass}
            width={230}
            handleChange={({target: { value }}) => this.props.actions.updateSelectedAssetClass(value)}
            classNameSuffix={"filter-menu__asset-class"}
            intlPrefix={"research-filter-asset"}
        />;
    }

    private renderRatingFilterMenu() {
        return <SelectComponent
            id={"filter-menu__rating"}
            values={ratingFilterItems}
            selected={this.props.selectedRating}
            width={230}
            handleChange={({target: { value }}) => this.props.actions.updateSelectedRating(value)}
            classNameSuffix={"filter-menu__rating"}
            renderValue={(selectedId) =>
                <FormattedMessage id={`research-filter-rating.${selectedId}`}
                                  defaultMessage={ratingFilterItems.find((value) => value.id === selectedId)!.name}
                />}
            menuItemTransform={(it) =>
                <>
                    <RatingIconComponent rating={it.id as RatingTypeFilter}/>
                    <div>
                        <FormattedMessage id={`research-filter-rating.${it.id}`}
                                          defaultMessage={it.name} />
                    </div>
                </>}
        />;
    }

    private renderEditControlButton() {
        if (!hasConsultantLevelAccess() || this.props.planCountryName === "UK") {
            return;
        }

        return this.props.editingPlanPortfolio
            ? this.renderDoneEditingButton()
            : this.renderEditPortfolioButton();
    }

    private renderEditPortfolioButton() {
        return <RaisedButton
            className="edit-portfolio-button"
            style={ defaultButtonStyle }
            onClick={this.props.actions.beginEditPortfolio}
            primary={true}
        >
            <FormattedMessage id="research.edit-portfolio"
            defaultMessage="Edit Portfolio" />
        </RaisedButton>;
    }

    private renderDoneEditingButton() {
        return <RaisedButton
            className="done-editing-button"
            style={prominentButtonStyle}
            primary={true}
            onClick={this.props.actions.endEditPortfolio}>
            <FormattedMessage id="research.done-editing"
            defaultMessage="Done Editing" />
        </RaisedButton>;
    }

    private renderClearFiltersLink() {
        return (
            <div className="clear-filters-container">
                <a className="clear-filters link-14 clickable"
                   data-testid="clear-filters"
                   onClick={() => {
                    this.props.actions.clearFilters();
                }}>
                    <FormattedMessage id="research.clear-filters"
                        defaultMessage="Clear Filters" />
                </a>
            </div>
        );
    }

    private renderManagerSortMenu() {
        return <SelectComponent
            id={"sort-menu__manager"}
            values={managerSearchItems}
            selected={this.props.selectedSortOption}
            width={230}
            handleChange={({target: { value }}) => this.props.actions.updateSelectedSortOption(value)}
            classNameSuffix={"sort-menu__sort"}
            intlPrefix={"research-sort"}
        />;
    }

    private hasAssetAccess(assetClass: AssetClassFilter) {
        const assetClasses = getAssetClasses(this.props.portfolioProductSummaries);

        switch (assetClass) {
            case AssetClassFilter.ALL:
                return true;
            case AssetClassFilter.EQUITY:
                return assetClasses.equity;
            case AssetClassFilter.FIXED_INCOME:
                return assetClasses.fixedIncome;
            case AssetClassFilter.LIQUID_ALTERNATIVE:
                return assetClasses.liquidAlternative;
            case AssetClassFilter.MULTIASSET_DGFTTAA:
                return assetClasses.multiAsset;
            case AssetClassFilter.CASH:
                return assetClasses.cash;
            default:
                return false;
        }
    }

}

export const mapStateToProps = (state: IApplicationRootState): IResearchFilterPropsFromStore => {
    return {
        selectedAssetClass: state.filterAndSortState!.selectedAssetClass,
        selectedRating: state.filterAndSortState!.selectedRatingType,
        selectedSortOption: state.filterAndSortState!.selectedSortOption,
        editingPlanPortfolio: isEditingPlanPortfolio(state),
        selectedAttributes: state.filterAndSortState!.selectedAttributes,
        portfolioProductSummaries: getSuccessData(portfolioProductSummariesSelector(state))!,
        planCountryName: planCountryName(state),
    };
};

const getAssetClasses = (productSummaries: Map<number, ProductSummary>): AssetClassOptions => {
    const assetTypes = productSummaries.valueSeq().map((x: ProductSummary) => x.assetType).toSet();
    return new AssetClassOptions(
        assetTypes.contains(AssetClass.EQUITY),
        assetTypes.contains(AssetClass.FIXED_INCOME),
        assetTypes.contains(AssetClass.LIQUID_ALTERNATIVE),
        assetTypes.contains(AssetClass.MULTIASSET_DGFTTAA),
        assetTypes.contains(AssetClass.CASH),
    );
};

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
        return {
            actions: bindActionCreators({
                    ...filterActions,
                    ...adminActions,
                },
                dispatch),
        };
    }
;

const ResearchFilterAndSort =
    connect<any, any, any>(mapStateToProps, mapDispatchToProps)
    (ResearchFilterAndSortComponent);

export default ResearchFilterAndSort;
