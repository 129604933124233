import * as React from 'react';
import {withVerifyAccess} from "../common/withVerifyAccess";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {hasCprSharedMediaAdmin} from "../../../mainReducerMapSelectors";
import {useDispatch, useSelector} from "react-redux";
import {ErrorComponent} from "../base/Error.component";
import {useEffect, useState} from "react";
import {IMediaFile, MediaApi} from "../../api/MediaApi";
import {MediaFileUpload} from "./MediaFileUpload";
import {setHeaderNotification} from "../base/header/HeaderActions";
import {NotificationTypes} from "../base/header/HeaderReducer";

export const SharedMediaFilesPage: React.FunctionComponent = () => {
    const hasCprSharedMediaAdminAccess = useSelector(state => hasCprSharedMediaAdmin(state));
    const [sharedMediaFiles, setSharedMediaFiles] = useState<IMediaFile[]>([]);
    const [showFileUpload, setShowFileUpload] = useState<Boolean>(false);
    const dispatch = useDispatch();
    const fetchSharedMediaFiles = () => {
        MediaApi.fetchNonPlanSpecificMediaFiles().then((response) => {
            setSharedMediaFiles(response);
        });
    };

    useEffect(() => {
        if (hasCprSharedMediaAdminAccess) {
            fetchSharedMediaFiles();
        }
    },[]);

    const renderSharedMediaFilesTable = () => {
        return (
            <div className="cpr-shared-media-files__table-container">
                <table className="cpr-shared-media-files__table">
                    <thead>
                        <tr>
                            <th className="cpr-shared-media-files__table-header-title-long">Media Group Name</th>
                            <th className="cpr-shared-media-files__table-header-title">Quarter</th>
                            <th className="cpr-shared-media-files__table-header-title">Year</th>
                            <th className="cpr-shared-media-files__table-header-title">Region</th>
                            <th className="cpr-shared-media-files__table-header-title-service-type">Service Type</th>
                            <th className="cpr-shared-media-files__table-header-title">Language</th>
                            <th className="cpr-shared-media-files__table-header-title-label">Label</th>
                            <th className="cpr-shared-media-files__table-header-title-long">File Name</th>
                            <th className="cpr-shared-media-files__table-header-title-link"/>
                            <th className="cpr-shared-media-files__table-header-title-link"/>
                        </tr>
                    </thead>
                    <tbody>{ renderSharedMediaFiles() }</tbody>
                </table>
            </div>
        );
    };

    const renderSharedMediaFiles = () => {
        return sharedMediaFiles!.map(renderSharedMediaFile);
    };

    const renderSharedMediaFile = (sharedMediaFile: IMediaFile) => {
        return (
            <tr className="cpr-shared-media-file__row" key={sharedMediaFile.id}>
                <td className="cpr-shared-media-file__name" key={sharedMediaFile.mediaGroupName}>
                    {sharedMediaFile.mediaGroupName}
                </td>
                <td className="cpr-shared-media-file__quarter" key={sharedMediaFile.quarter}>
                    Q{sharedMediaFile.quarter}
                </td>
                <td className="cpr-shared-media-file__year" key={sharedMediaFile.year}>
                    {sharedMediaFile.year}
                </td>
                <td className="cpr-shared-media-file__region" key={`region_${sharedMediaFile.region}`}>
                    {sharedMediaFile.region}
                </td>
                <td className="cpr-shared-media-file__service-type" key={`service_type_${sharedMediaFile.serviceType}`}>
                    {sharedMediaFile.serviceType}
                </td>
                <td className="cpr-shared-media-file__language" key={`language_${sharedMediaFile.language}`}>
                    {sharedMediaFile.language}
                </td>
                <td className="cpr-shared-media-file__label" key={sharedMediaFile.label}>
                    {sharedMediaFile.label}
                </td>
                <td className="cpr-shared-media-file__file-name" key={sharedMediaFile.fileName}>
                    {sharedMediaFile.fileName}
                </td>
                <td>
                    <a className="cpr-shared-media-file__view"
                       onClick={() => MediaApi.getDocumentDownload(sharedMediaFile.id!)}
                   >View</a>
                </td>
                <td>
                    <a className="cpr-shared-media-file__delete"
                       onClick={() =>
                           deleteMediaFile(sharedMediaFile.id!)}>
                        Delete
                    </a>
                </td>
            </tr>
        );
    };

    const hideAddFile = () => {
        setShowFileUpload(false);
    };

    const renderNonPlanSpecificMediaFileFileUpload = ()=> {
        return(
            showFileUpload ?
                <MediaFileUpload
                    hide={hideAddFile}
                    onUpload={fetchSharedMediaFiles}
                    isPlanSpecific={false}/>
                : null
        );
    };

    const deleteMediaFile = (mediaFileId: number) => {
        const processFailure = () => {
            dispatch(setHeaderNotification(
                {message: "The file has failed to delete, please try again",
                    notificationType: NotificationTypes.FAILURE},
                5000));
        };
        const processSuccess = () => {
            dispatch(setHeaderNotification(
                {message: "The file has been successfully deleted",
                    notificationType: NotificationTypes.SUCCESS},
                5000));
            fetchSharedMediaFiles();
        };

        MediaApi.deleteMediaFile(mediaFileId)
            .then(() => processSuccess())
            .catch(() => processFailure());
    };

    return hasCprSharedMediaAdminAccess
            ? <div className="cpr-shared-media-admin-page__container new-common-styles">
                <div className="cpr-shared-media-admin-page__header">
                    <h1>CPR Shared Media Files Administration</h1>
                </div>
                <div className="cpr-shared-media-admin-page__table-container">
                    { renderSharedMediaFilesTable() }
                </div>
                <div className="addMediaFile">
                    <a className="cpr-shared-media-admin-page__add-file"
                       onClick={()=>setShowFileUpload(!showFileUpload)}>
                        + Add File
                    </a>
                </div>
                <div>
                    { renderNonPlanSpecificMediaFileFileUpload() }
                </div>
            </div>
            : <ErrorComponent />;
};

export default withVerifyAccess(SharedMediaFilesPage, UserTypeEnum.CONSULTANT);