import * as React from "react";
import {useEffect} from "react";
import {connect} from "react-redux";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import {watchVideosButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import {showMasterLectureSeries} from "../../utils/planUtil";
import {ErrorComponent} from "../base/Error.component";
import {displayBetaMessage, hideBetaMessage} from "./MasterLectureSeriesActions";

export interface IMasterLectureSeriesPropsFromActions {
    actions: {
        displayBetaMessage: typeof displayBetaMessage;
        hideBetaMessage: typeof hideBetaMessage;
    };
}

type MasterLectureSeriesProps = IMasterLectureSeriesPropsFromActions;

export const MasterLectureSeriesPage: React.FunctionComponent<MasterLectureSeriesProps> = (props) => {

    if (!showMasterLectureSeries()) {
        return <ErrorComponent/>;
    }

    const message =
        "The Master Lecture Series is in Beta. New content is being added all the time, so check back often.";

    useEffect(() => {
        props.actions.displayBetaMessage(message);
    }, []);

    useEffect(() => {
        return () => {
            // The code in here will run before unmount
            props.actions.hideBetaMessage();
        };
    }, []);

    return <div className="master-lecture-series__container" data-testid="master-lecture-series__container">
        <h1 className="master-lecture-series__header">Master Lecture Series</h1>
        <div className="master-lecture-series__description-and-button">
            <div className="master-lecture-series__description" data-testid="master-lecture-series__description">
                <div className="master-lecture-series__description-section">
                    <h2>About the MLS</h2>
                    The Master Lecture Series (MLS) is a mobile device accessible, online video library intended for
                    all AHIC employees.
                    <ul>
                        <li><b>Format:</b> The video modules are concise, 15- to 20-minute topical reviews provided
                            by our own subject matter experts.
                        </li>
                        <li><b>Audience:</b> They are intended to have a long shelf life, answering common
                            questions you might have as a new hire or a veteran needing a quick refresher.
                            </li>
                            <li><b>Productivity:</b> For more complex topics, the modules may build upon one another
                                in a lecture series you can explore at your own pace.
                            </li>
                            <li><b>Content:</b> The lecture slides are available in PPT format.</li>
                        </ul>
                    </div>
                    <div className="master-lecture-series__description-section">
                        <h2>Getting involved</h2>
                        <ul>
                            <li>If you are a <b>subject matter expert,</b> we encourage you to consider creating a
                                Master Lecture Series video as a way to better leverage yourself and your expertise!
                            </li>
                            <li>If you’re a <b>curious investor</b>, consider joining our volunteer team of content
                                creators.
                            </li>
                            <li>If you’re a <b>people manager</b>, we encourage you to consider creating a video library
                                page or
                                curriculum for your colleagues. We have created four pages based on initial demand (New
                                Hires,
                                Client Service, Operations, and Manager Research).
                            </li>
                        </ul>
                    </div>
                    {/* eslint-disable-next-line react/forbid-dom-props */}
                    Please reach out to <a href="mailto:chetan.grover@aon.com"> Chetan Grover</a>,
                    {/* eslint-disable-next-line react/forbid-dom-props */}
                    <a href="mailto:mark.tavares@aon.com"> Mark Tavares</a>,
                    {/* eslint-disable-next-line react/forbid-dom-props */}
                    <a href="mailto:steph.hannant@aon.com"> Steph Hannant</a>,
                    {/* eslint-disable-next-line react/forbid-dom-props */}
                    <a href="mailto:mitch.vermet@aon.com"> Mitch Vermet</a>, or
                    {/* eslint-disable-next-line react/forbid-dom-props */}
                    <a href="mailto:matt.clink.3@aon.com"> Matt Clink</a> to learn more.
                </div>
                <form className="master-lecture-series__description-form master-lecture-series__description-button"
                      method="post"
                      action="https://brightcove.aoniris.com/login?redirect=%2F"
                      target="_blank">
                    <div>
                        <input type="hidden" name="username" value="%7B%7D"/>
                        <input type="hidden" name="password" id="password" value="mls"/>
                        <RaisedButton
                            type="submit"
                            className="master-lecture-series__brightcove-link"
                            primary={true}
                            style={watchVideosButtonStyle}>
                            Watch Videos
                        </RaisedButton>
                    </div>
                </form>
            </div>
            <form className="master-lecture-series__description-form" method="post"
                  action="https://brightcove.aoniris.com/login?redirect=%2F" target="_blank">
                <div>
                    <input type="hidden" name="username" value="%7B%7D"/>
                    <input type="hidden" name="password" id="password" value="mls"/>
                    <RaisedButton
                        type="submit"
                        className="master-lecture-series__brightcove-link"
                        primary={true}
                        style={watchVideosButtonStyle}>
                        Watch Videos
                    </RaisedButton>
                </div>
            </form>
    </div>;
};

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IMasterLectureSeriesPropsFromActions => {
    return {
        actions: bindActionCreators({
            displayBetaMessage,
            hideBetaMessage,
        }, dispatch),
    };
};

const connectedMasterLectureSeriesComponent =
    connect<MasterLectureSeriesProps, {}>(null, mapDispatchToProps)(MasterLectureSeriesPage);

connectedMasterLectureSeriesComponent.displayName = "MasterLectureContent";

export default connectedMasterLectureSeriesComponent;
