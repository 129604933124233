import * as React from "react";
import {useState} from "react";
import {FormattedMessage} from "react-intl";

export interface IProductCardProps {
    name: string;
    rating: string | undefined;
    ratingClass: string | undefined;

}

export const ProductCard: React.FunctionComponent<IProductCardProps> = (props) => {
    const [panelExpanded, setPanelExpanded] = useState<boolean>(false);

    function handleExpandChange() {
        setPanelExpanded(!panelExpanded);
    }

    function renderChildren() {
        return panelExpanded
            ? <div data-testid="product-card__children">{props.children}</div>
            : null;
    }

    return <div className="product-card__container"
                data-testid="product-card__container">
        <div className={`product-card__rating-top ${panelExpanded ? "expand" : "collapse"}`}
             data-testid="product-card__rating-top"
             onClick={handleExpandChange}>
            <h3 className={`product-card__rating-title ${panelExpanded ? "expanded" : "collapsed"}`}>
                <FormattedMessage
                    id={`product-card.${props.name}`}
                    defaultMessage={props.name}
                />
            </h3>
            {props.rating ? <div className="product-card__rating-container">
                    <span className="product-card__current-rating" data-testid="product-card__current-rating">
                        {props.rating}
                    </span>
                    <span
                        className={`product-card__rating-icon rating-icon ${props.ratingClass}`}
                        role={`${props.ratingClass}`}/>
                    <div className="product-card__toggle-icon-container">
                        <i className={`fa fa-regular ${panelExpanded ? "fa-minus minus" : "fa-plus plus"}`}
                           data-testid="product-card__toggle-icon"
                        />
                    </div>
                </div>
                : null
            }
        </div>
        {renderChildren()}
    </div>;
};