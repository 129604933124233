import * as React from "react";
import {IInformPerformance} from "../../model/inform/InformPerformance";
import {
    formatInViewDate,
} from "./informUtil";
import {PerformanceCumulativeAbsoluteChart} from "./PerformanceCumulativeAbsoluteChart";
import {PerformanceMonthlyExcessChart} from "./PerformanceMonthlyExcessChart";

export const CHART_SOURCE = "Source: eVestment";

export const PerformanceComponent: React.FunctionComponent<IInformPerformance> = (props: IInformPerformance) => {
    const renderMonthlyAccessChart = () => {
        if (!props.monthlyExcessReturnValues || props.monthlyExcessReturnValues.length === 0) {
            return null;
        }
        return <PerformanceMonthlyExcessChart {...props}/>;
    };

    const renderChartTitleAndDate = (title: string) => {
        return <div className="inform-performance__chart-title" data-testid="inform-performance__chart-title">
            <h3>{title}</h3>
            {
                props.ifaRatingEffectiveDate &&
                <div className="inform-performance__chart-date" data-testid="inform-performance__chart-date">
                    <div>{`Ending ${formatInViewDate(props.ifaRatingEffectiveDate)}`}</div>
                    <div>{CHART_SOURCE}</div>
                </div>
            }
        </div>;
    };

    const hasMonthlyAccess = props.monthlyExcessReturnValues && props.monthlyExcessReturnValues.length > 0;
    const renderMonthlyExcess = () => {
        return hasMonthlyAccess &&
            <div className="inform-performance__monthly-excess" data-testid="inform-performance__monthly-excess">
                {renderChartTitleAndDate(props.monthlyExcessReturnChartAxisTitle)}
                {renderMonthlyAccessChart()}
            </div>;
    };

    const renderCumulativeAbsolute = () => {
        if (!props.productCumulative || props.productCumulative.length === 0) {
            return null;
        }
        const containerId = "inform-performance__cumulative-absolute-container";
        return <div className={containerId} data-testid={containerId}>
            <div className="inform-performance__cumulative-absolute-chart-container">
                {renderChartTitleAndDate(props.cumulativeAbsoluteChartAxisTitle)}
                <PerformanceCumulativeAbsoluteChart {...props}/>
            </div>
            <div className="inform-performance__cumulative-absolute-periods-container">
                <div className="inform-performance__cumulative-absolute-positive-periods"
                     data-testid="inform-performance__cumulative-absolute-positive-periods">
                    <h3>Positive Periods</h3>
                    <p>
                        Out of the {props.monthsBenchmarkUp} months when the benchmark is
                        up, the strategy has outperformed it {props.percentageOutperformanceUp} of
                        the time.
                    </p>
                    <div className="positive-periods__product">
                        <div className="positive-periods__product-text">Product Average Absolute Returns</div>
                        <div className="positive-periods__product-value">
                            {props.positivePeriodsProductAverageAbsoluteReturns}
                        </div>
                    </div>
                    <div className="positive-periods__benchmark">
                        <div className="positive-periods__benchmark-text">Benchmark Average Absolute Returns</div>
                        <div className="positive-periods__benchmark-value">
                            {props.positivePeriodsBenchmarkAverageAbsoluteReturns}
                        </div>
                    </div>
                </div>
                <div className="inform-performance__cumulative-absolute-negative-periods"
                     data-testid="inform-performance__cumulative-absolute-negative-periods">
                    <h3>Negative Periods</h3>
                    <p>
                        Out of the {props.monthsBenchmarkDown} months when the benchmark is
                        down, the strategy has outperformed it {props.percentageOutperformanceDownBenchmarkDown} of
                        the time.
                    </p>
                    <div className="negative-periods__product">
                        <div className="negative-periods__product-text">Product Average Absolute Returns</div>
                        <div className="negative-periods__product-value">
                            {props.negativePeriodsProductAverageAbsoluteReturns}
                        </div>
                    </div>
                    <div className="negative-periods__benchmark">
                        <div className="negative-periods__benchmark-text">Benchmark Average Absolute Returns</div>
                        <div className="negative-periods__benchmark-value">
                            {props.negativePeriodsBenchmarkAverageAbsoluteReturns}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    };

    return <div className="inform-performance__container" data-testid="inform-performance__container">
        {renderCumulativeAbsolute()}
        {renderMonthlyExcess()}
    </div>;
};