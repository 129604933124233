import * as React from "react";
import {CSSProperties, ReactNode, useState} from "react";

export interface ICardProps {
    className?: string;
    expanded: boolean;
    header: ReactNode;
    onExpandChange?: () => void;
    headerContainerStyle?: CSSProperties;
    headerStyle?: CSSProperties;
    childrenContainerStyle?: CSSProperties;
    expandableButtonStyle?: CSSProperties;
    showExpandableButton?: boolean;
}

export const Card: React.FunctionComponent<ICardProps> = (props) => {
    const [currentButtonClass, setCurrentButtonClass] = useState(props.expanded
        ? "card__button-open fa-regular fa-chevron-up"
        : "card__button-closed fa-regular fa-chevron-down");
    const [ripple, setRipple] = useState("init");

    function changeExpandButtonSvg() {
        props.expanded
            ? setCurrentButtonClass("card__button-closed fa-regular fa-chevron-down")
            : setCurrentButtonClass("card__button-open fa-regular fa-chevron-up");
    }

    function handleRipple() {
        setRipple("init ripple");

        setTimeout(() => setRipple("init"), 600);
    }

    function renderExpandableButton() {
        return props.showExpandableButton
            ? <div className="card__expandable-button" data-testid="card__expandable-button">
                <div className={currentButtonClass} style={props.expandableButtonStyle} onClick={handleRipple}>
                    <div className={`${ripple}`}/>
                </div>
            </div>
            : null;
    }

    function renderChildren() {
        return props.expanded
            ? <div className={`card__children`} style={props.childrenContainerStyle}>
                {props.children}
            </div>
            : null;
    }

    function handleExpandChange() {
        changeExpandButtonSvg();
        if (!!props.onExpandChange) {
            props.onExpandChange();
        }
    }

    return <div className={`shared-components__card ${props.className}`}>
        <div
            data-testid={"shared-components__card-header"}
            className="card__header"
            style={props.headerContainerStyle}
            onClick={handleExpandChange}>
            <div className="card__header-text" style={props.headerStyle}>{props.header}</div>
            {renderExpandableButton()}
        </div>
        <div style={{flexGrow: 2}}/>
        {renderChildren()}
    </div>;
};

Card.displayName = "MyCard";
