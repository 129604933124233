import {Map} from "immutable";
import * as React from "react";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import {
    allFlashReportsSummariesSelector,
    allMeetingNotesSelector,
    clientPerformanceReportingAccess,
    cprPortfolioOverviewEnabled,
    currentPlan,
    hasAonTrustCompany,
    hasResearchAccess,
    insightsEnabled,
    planCountryName,
    sharedDocumentsEnabled,
} from "../../../mainReducerMapSelectors";
import {FlashReportSummary} from "../../model/FlashReportSummary.model";
import {IHomeContentBoxDataItem, IHomeListItem} from "../../model/IHomeListItem";
import {MeetingNoteSummary} from "../../model/MeetingNoteSummary.model";
import {SharedDocument} from "../../model/SharedDocument.model";
import {Card} from "../common/Card";
import {defaultCardTitleStyle} from "../common/cardStyles";
import {byDate} from "../../utils/dateUtil";
import {Brackets, BracketsBox} from "../base/Brackets.component";
import {getSuccessData, REQUEST_STATES, RequestState} from "../common/commonStates";
import {
    allFlashReportsSummariesRequestPair,
    allMeetingsNotesRequestPair,
    capitalMarketAssumptionsRequestPair, marketBackgroundsRequestPair,
    mediumTermViewsRequestPair,
    quarterlyInvestmentOutlookRequestPair,
    whitePapersRequestPair,
} from "../common/RequesterPairs";
import {requestAllFlashReportSummaries} from "../flash-report/AllFlashReportsActions";
import {IThoughtLeadershipState} from "../thought-leadership/ThoughtLeadershipReducer";
import {connectedHomeContentBox, contentBox, HomeContentBox} from "./HomeContentBox.component";
import {getThreeMostRecent} from "./homePageService";
import {SharedDocumentApi} from "../../api/SharedDocumentApi";
import {IPlanInfo} from "../../model/ClientsAndPlans.model";
import {CprPortfolioOverview} from "./CprPortfolioOverview";
import {useEffect, useState} from "react";
import {IApplicationRootState} from "../../../applicationState";

class CardData {
    constructor(public title: string, public cardText: string, public link: string, public id: string) {
    }
}

export const ATC_SHARED_DESCRIPTION = "Aon Collective Investment Trust (“ACIT”) Fund Documents";
export const ATC_SHARED_DOC = "ACIT Shared Documents";

const researchData = new CardData(
    "Find Aon’s ratings & manager analysis.",
    "Managers & Strategies",
    "/research",
    "home-card-research",
);

const sharedDocumentData = new CardData(
    "Get documents shared by your consultant.",
    "Shared Documents",
    "/shared-documents",
    "home-card-shared-documents",
);

const trustCompanySharedDocumentData = new CardData(
    ATC_SHARED_DESCRIPTION,
    ATC_SHARED_DOC,
    "/shared-documents",
    "home-card-shared-documents",
);

const whitePapersData = new CardData(
    "See Aon’s point of view on financial markets.",
    "Insights",
    "/white-papers",
    "home-card-thought-leadership",
);

export interface IHomePropsFromStore {
    hasResearchAccess: boolean;
    allFlashReportSummaries: Map<number, FlashReportSummary>;
    thoughtLeadership: IThoughtLeadershipState;
    allMeetingNoteSummaries: Map<number, MeetingNoteSummary>;
    sharedDocumentsEnabled: boolean;
    planCountryName: string;
    insightsEnabled: boolean;
    currentPlan: IPlanInfo;
    clientPerformanceReportingAccess: boolean;
    cprPortfolioOverviewEnabled: boolean;
    aonTrustCompany: boolean
}

export interface IHomePageActions {
    actions: {
        requestAllFlashReportSummaries: typeof requestAllFlashReportSummaries,
    };
}

export const ATC_DISCLAIMER_P1 = "Aon Trust Company LLC (ATC), an Illinois non-depository, state-chartered bank, is the Trustee\n" +
    "              for the Aon Collective Investment Trust (“ACIT”). The ACIT Funds are collective investment trust funds –\n" +
    "              they are not mutual funds, are not FDIC insured, nor registered with the U.S. Securities and Exchange\n" +
    "              Commission, and not guaranteed by ATC, any affiliate or any government agency.  This is not an offer to\n" +
    "              sell or the solicitation of an offer to purchase a beneficial interest in the Funds. Please read the\n" +
    "              ACIT Offering Statement and any other supplements and disclosure documents for important information.";

export const ATC_DISCLAIMER_P2 = "The documents provided are for the use of plan sponsors and their intermediaries. They are not intended\n" +
    "              for use and/or distribution to the general public.";

export type IHomeProps = IHomePropsFromStore & IHomePageActions;

export const Home: React.FunctionComponent<IHomePropsFromStore> = (props) => {
    const [sharedDocuments, setSharedDocuments] = useState<SharedDocument[]>([]);

    const getSharedDocuments = () => {
        SharedDocumentApi.requestRecentSharedDocuments().then((response) => {
            setSharedDocuments(response.data!);
        });
    };

    useEffect(() => {
        getSharedDocuments();
    },[]);

    const itemList = (dataList: IHomeListItem[]):IHomeContentBoxDataItem[] => {
        return dataList.map((it) => it.transform());
    };

    const renderCard = (cardData: CardData) => {
        return <Card
            className="home-card__container"
            headerContainerStyle={defaultCardTitleStyle}
            header={<FormattedMessage id={`home.${cardData.id}-title`} defaultMessage={cardData.title}/>}
            expanded={true}>
            <Link className="home-card__link" to={cardData.link}>
                <div id={cardData.id} className="home-card-text">
                    <FormattedMessage id={`home.${cardData.id}-desc`} defaultMessage={cardData.cardText}/>
                    <div className="navigation__forward-arrow-small fa-regular fa-arrow-right"/>
                </div>
            </Link>
        </Card>;
    };

    const  renderContent = () => {
        return <div className="new-common-styles page-min-width-1024" data-testid="home-page">
            <div
                className="home-container"
                style={{
                    maxWidth: `${getCardsContainerWidth()}px`,
                }}>
                {renderCprPortfolioOverview()}
                <div className="home-cards">
                    {renderResearchCard()}
                    {renderSharedDocumentsCard()}
                    {renderInsightsCard()}
                </div>
                <div className="home-content">
                    <Brackets>
                        {getContentBoxes().map((homeContentBox, index) => {
                            return <BracketsBox className="box-margin" key={index}>
                                {homeContentBox}
                            </BracketsBox>;
                        })}
                    </Brackets>
                </div>
                {renderDisclaimer()}
            </div>
        </div>;
    };

    const renderCprPortfolioOverview = () => {
        return props.currentPlan.powerbiId
            ? <CprPortfolioOverview
                clientPerformanceReportingAccess={props.clientPerformanceReportingAccess}
                cprPortfolioOverviewEnabled={props.cprPortfolioOverviewEnabled}
                />
            : null;
    };

    const renderSharedDocumentsCard = () => {
        return props.sharedDocumentsEnabled
            ? renderCard(props.aonTrustCompany ? trustCompanySharedDocumentData : sharedDocumentData)
            : null;
    };

    const renderInsightsCard = () => {
        return props.insightsEnabled
            ? renderCard(whitePapersData)
            : null;
    };

    const renderResearchCard = () => {
        return props.hasResearchAccess
            ? renderCard(researchData)
            : null;
    };

    const getContentBoxes = () => {
        return [
            renderStrategyUpdates(),
            renderSharedDocuments(),
            renderThoughtLeadership(),
        ].filter((x) => x);
    };

    const getCardWidth = () => {
        return getCardCount() > 2 ? 500 : 760;
    };

    const getCardCount = () => {
        const sharedDocCard = props.sharedDocumentsEnabled ? 1 : 0;
        const researchCard = props.hasResearchAccess ? 1 : 0;

        return 1 + sharedDocCard + researchCard;
    };

    const getCardsContainerWidth = () => {
        return getCardCount() * getCardWidth() + 20;
    };

    const sortLeadershipDocuments = (documents: IThoughtLeadershipState): IHomeListItem[] => {
        if (requestsCompleted(Object.values(documents))) {
            const whitePapers = getSuccessData(documents.whitePapersState)!;
            const capitalMarketAssumptions = getSuccessData(documents.capitalMarketAssumptionsState)!;
            const mediumTermViews = getSuccessData(documents.mediumTermViewsState)!;
            const marketBackgrounds = getSuccessData(documents.marketBackgroundsState)!;

            const quarterlyInvestmentOutlooks: IHomeListItem[] =
                props.planCountryName === "Canada"
                    ? getSuccessData(documents.quarterlyInvestmentOutlookState)!
                    : [];

            const flattenedCMA = capitalMarketAssumptions.valueSeq().toArray();

            const leadershipDocuments: IHomeListItem[] = [
                ...whitePapers,
                ...flattenedCMA,
                ...mediumTermViews,
                ...quarterlyInvestmentOutlooks,
                ...marketBackgrounds,
            ];

            return leadershipDocuments.sort(byDate).slice(0, 3);
        }
        return [];
    };

    const requestsCompleted = (requestStateArray: RequestState<any>[]): boolean => {
        return requestStateArray.reduce((acc, arr) => {
            if (arr.kind === REQUEST_STATES.REQUEST_SUCCEEDED) {
                return acc + 1;
            }
            return acc;
        }, 0) === requestStateArray.length;
    };

    const renderStrategyUpdates = () => {
        if(props.hasResearchAccess) {
            const renderFunction = () => {
                const homeItems = [
                    ...props.allMeetingNoteSummaries.valueSeq().toArray(),
                    ...props.allFlashReportSummaries.valueSeq().toArray(),
                ];
                const threeMostRecentHomeItems = getThreeMostRecent(homeItems);
                const contentBoxProps = {
                    title: "Recent Updates",
                    translationId: "recent-updates",
                    values: itemList(threeMostRecentHomeItems),
                    shouldTitlesBeLinks: true,
                    shouldDocumentsDownload: false,
                };

                return contentBox(contentBoxProps);
            };
            const Box = connectedHomeContentBox(
                [allMeetingsNotesRequestPair, allFlashReportsSummariesRequestPair], renderFunction);
            return <Box className="home-content__strategy-updates-box"/>;
        }
        return null;
    };

    const renderSharedDocuments = () =>  {
        if (props.sharedDocumentsEnabled) {
            return <HomeContentBox title="New Documents"
                                   translationId="new-documents"
                                   values={itemList(sharedDocuments)}
                                   shouldTitlesBeLinks={false}
                                   shouldDocumentsDownload={true}
            />;
        }
        return null;
    };

    const renderThoughtLeadership = () => {
        if(!props.insightsEnabled) {
            return  null;
        }

        const renderFunction = () => {
            const leadershipDocuments = sortLeadershipDocuments(props.thoughtLeadership);
            const prop = {
                title: "Insights",
                translationId: "insights",
                values: itemList(leadershipDocuments),
                shouldTitlesBeLinks: false,
                shouldDocumentsDownload: true,
            };
            return contentBox(prop);
        };

        const Box = connectedHomeContentBox([
            capitalMarketAssumptionsRequestPair,
            mediumTermViewsRequestPair,
            whitePapersRequestPair,
            quarterlyInvestmentOutlookRequestPair,
            marketBackgroundsRequestPair,
        ], renderFunction);

        return <Box className="home-content__insights-box"/>;
    };

    const renderDisclaimer = () => {
        return props.aonTrustCompany
            ? <div className="home-content__atc-disclaimer" data-testid="home-content__atc-disclaimer">
                {ATC_DISCLAIMER_P1}
                <p>{ATC_DISCLAIMER_P2}</p>
            </div>
            : null;
    };

    return renderContent();
};

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IHomePageActions => {
    return {
        actions:
            bindActionCreators({
                requestAllFlashReportSummaries,
            }, dispatch),
    };
};

export const mapStateToProps = (state: IApplicationRootState): IHomePropsFromStore => {
    return {
        hasResearchAccess: hasResearchAccess(state),
        allFlashReportSummaries: getSuccessData(allFlashReportsSummariesSelector(state))!,
        thoughtLeadership: state.thoughtLeadership!,
        allMeetingNoteSummaries: getSuccessData(allMeetingNotesSelector(state))!,
        sharedDocumentsEnabled: sharedDocumentsEnabled(state),
        planCountryName: planCountryName(state),
        insightsEnabled: insightsEnabled(state),
        currentPlan: currentPlan(state),
        clientPerformanceReportingAccess: clientPerformanceReportingAccess(state),
        cprPortfolioOverviewEnabled: cprPortfolioOverviewEnabled(state),
        aonTrustCompany: hasAonTrustCompany(state)
    };
};

const connectedComponent = connect<IHomePropsFromStore, IHomePageActions>(mapStateToProps, mapDispatchToProps)(Home);

export default connectedComponent;
