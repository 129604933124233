import * as React from "react";
import {withVerifyAccess} from "../common/withVerifyAccess";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {useEffect, useState} from "react";
import {IMediaGroup, MediaApi} from "../../api/MediaApi";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import { FormattedMessage } from "react-intl";
import {MediaGroupAddComponent} from "./MediaGroupAddComponent";

export const AdminMediaGroupsPage: React.FunctionComponent = () => {
    const [mediaGroups, setMediaGroups] = useState<IMediaGroup[] | []>([]);
    const [showAddMediaGroup, setShowAddMediaGroup] = useState<boolean>(false);
    const [showEditMediaGroup, setShowEditMediaGroup] = useState<boolean>(false);
    const [selectedMediaGroup, setSelectedMediaGroup] = useState<any>();

    const fetchMediaGroups = () => {
        MediaApi.fetchMediaGroups().then((response) => {
            setMediaGroups(response);
        });
    };

    useEffect(() => {
        fetchMediaGroups();
    }, []);


    const getTemplateByDocumentId = (templateDocumentId: number) => {
        MediaApi.getExportTemplatedDownload(templateDocumentId);
    };

    const renderMediaGroup = (mediaGroup: IMediaGroup) => {
        return <tr className="media-groups__row" key={mediaGroup.id}>
                    <td className="media-groups__id">{mediaGroup.id}</td>
                    <td className="media-groups__name">{mediaGroup.name}</td>
                    <td className="media-groups__plan-specific">{mediaGroup.planSpecific ? "Y" : "N"}</td>
                    <td className="media-groups__service-specific">{mediaGroup.serviceTypeSpecific ? "Y" : "N"}</td>
                    <td className="media-groups__language-specific">{mediaGroup.languageSpecific ? "Y" : "N"}</td>
                    <td className="media-groups__region-specific">{mediaGroup.regionSpecific ? "Y" : "N"}</td>
                    <td className="media-groups__export-template">
                        {mediaGroup.documentId &&
                            <a role="link" className="media-groups__view old-anchor" onClick={() => getTemplateByDocumentId(mediaGroup.documentId!)}>View</a>}
                    </td>
                    <td>
                        <a role="link" className="media-groups__edit" onClick={() => toggleEditMediaGroup(mediaGroup)}>Edit</a>
                    </td>
                    <td className="media-groups__delete">
                        <a>Delete</a>
                    </td>
                </tr>;
    };

    const toggleEditMediaGroup = (mediaGroup: IMediaGroup) => {
        setShowAddMediaGroup(false);
        setSelectedMediaGroup(mediaGroup);
        setShowEditMediaGroup(true);
    };

    const toggleAddMediaGroup = () => {
        setShowEditMediaGroup(false);
        setShowAddMediaGroup(true);
    };

    const renderMediaGroups = () => {
        return mediaGroups!.map(renderMediaGroup);
    };

    const renderMediaGroupsTable = () => {
        return mediaGroups 
        ? <div className="media-groups__table">
            <table>
                <thead>
                    <tr>
                        <th className="media-groups__table-header-title">
                            <span>
                                <FormattedMessage id="media-groups.id" defaultMessage="ID" />
                            </span>
                        </th>
                        <th className="media-groups__table-header-name">
                            <span>
                                <FormattedMessage id="media-groups.media-group-name" defaultMessage="Media Group Name"/>
                            </span>
                        </th>
                        <th className="media-groups__table-header-title">
                            <span>
                                <FormattedMessage id="media-groups.plan-specific" defaultMessage="Plan Specific" />
                            </span>
                        </th>
                        <th className="media-groups__table-header-title">
                            <span>
                                <FormattedMessage id="media-groups.service-type-specific" defaultMessage="Service Type Specific" />
                            </span>
                        </th>
                        <th className="media-groups__table-header-title">
                            <span>
                                <FormattedMessage
                                    id="media-groups.language-specific" defaultMessage="Language Specific" />
                            </span>
                        </th>
                        <th className="media-groups__table-header-title">
                            <span>
                                <FormattedMessage id="media-groups.region-specific" defaultMessage="Region Specific" />
                            </span>
                        </th>
                        <th className="media-groups__table-header-title">
                            <span>
                                <FormattedMessage id="media-groups.export-template" defaultMessage="Export Template" />
                            </span>
                        </th>
                        <th className="media-groups__table-header-title-empty"/>
                        <th className="media-groups__table-header-title-empty"/>
                    </tr>
                </thead>
                <tbody>{renderMediaGroups()}</tbody>
            </table>
            <div className="addMediaGroup">
                <a role="link" className="media-groups__add-new-media-group" onClick={() => toggleAddMediaGroup()}>
                    + Add Media Group
                </a>
            </div>
        </div>
        :  <LoadingSpinner/>;
    };
    const hideMediaGroupComponent = () => {
        setShowAddMediaGroup(false);
        setShowEditMediaGroup(false);
    };

    const renderMediaGroupComponent = () => {
        return showEditMediaGroup || showAddMediaGroup
            ? <MediaGroupAddComponent
                onSave={fetchMediaGroups}
                mediaGroup={showEditMediaGroup ? selectedMediaGroup : undefined}
                hide={hideMediaGroupComponent}/>
            : null;
    };


    return <div className="main max-width-1600">
        <div id="container" className="main-content media-groups-page__main-content new-common-styles">
            <div className="media-groups-page__header">
                <h1 className="admin-media-groups-page__header">
                    CPR Media Group Administration
                </h1>
            </div>
            <div className="media-groups-page__table-container">
                {renderMediaGroupsTable()}
            </div>
        </div>
        { renderMediaGroupComponent() }
    </div>;
};



export default withVerifyAccess(AdminMediaGroupsPage, UserTypeEnum.ADMIN);

