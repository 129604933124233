import * as React from "react";
import {History} from "history";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {withVerifyAccess} from "../common/withVerifyAccess";
import {useEffect, useState} from "react";
import PlanApi from "../../api/PlanApi";
import {AdminCreatePlanComponent} from "./AdminCreatePlan.component";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {Map as ImmutableMap} from "immutable";
import {ProductSummary} from "../../model/product/ProductSummary";
import {getSuccessData} from "../common/commonStates";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {adminClientsWithPlansPair, allProductsRequestPair} from "../common/RequesterPairs";
import {connect} from "react-redux";
import {ClientsAndPlans, IClientInfo} from "../../model/ClientsAndPlans.model";
import {ISelectValue} from "../common/Select.component";
import {byName} from "../../utils/listUtil";
import {IPlanAuthorization} from "../../model/PlanAuthorization.model";
import {IApplicationRootState} from "../../../applicationState";


export interface IAdminEditPlanProps {
    match: {
        params: {
            planId: string;
        };
    };
    history: History;
}

export interface IAdminEditPlanPropsFromStore {
    productUniverseSummaries: ImmutableMap<number, ProductSummary>;
    adminClientInfoState: ClientsAndPlans;
}

export type IAdminEditPlanPageProps = IAdminEditPlanProps & IAdminEditPlanPropsFromStore;

export const AdminEditPlanPage: React.FunctionComponent<IAdminEditPlanPageProps> = (props:IAdminEditPlanPageProps) => {
    const [plan, setPlan] = useState<IPlanAuthorization | undefined>(undefined);

    const planId = Number.parseInt(props.match.params.planId, 10);

    const clients: ISelectValue[] = props.adminClientInfoState
        .map((clientInfo: IClientInfo) => ({id: clientInfo.id, name: clientInfo.name}))
        .sort(byName)
        .valueSeq()
        .toArray();

    useEffect(() => {
        PlanApi.requestPlan(planId).then((response) => {
            setPlan(response);
        });
    }, [props]);

    const cancelEdit = () => {
        props.history.push("/admin-client-plan");
    };

    const renderPlan = () => {
        return plan
            ? <div>
                <h2 className="admin-edit-plan-page__plan-name">{plan.name}</h2>
                <AdminCreatePlanComponent products={props.productUniverseSummaries}
                                          selectedClientId={plan.clientId}
                                          onCancel={cancelEdit}
                                          editPlan= {{plan, planId, clients}}
                />
            </div>
            : <LoadingSpinner/>;
    };

    return <div className="page-container new-common-styles admin-edit-plan-page__container">
        <h1 className="admin-edit-plan-page__header">Edit Plan</h1>
        {renderPlan()}
    </div>;
};

export const mapStateToProps = (state: IApplicationRootState): IAdminEditPlanPropsFromStore => {
    return {
        productUniverseSummaries: getSuccessData(state.allProductsStore!.productUniverseSummaries)!,
        adminClientInfoState: getSuccessData(state.adminState!.adminClientInfoState)!,
    };
};

const connected = connect<IAdminEditPlanPropsFromStore>(mapStateToProps)(AdminEditPlanPage);

const withRequester = multipleApiRequesterWrapper(connected, [
    allProductsRequestPair(),
    adminClientsWithPlansPair,
]);

export default withVerifyAccess(withRequester, UserTypeEnum.ADMIN);