import * as React from "react";

export class Brackets extends React.Component<any, any> {
    public render() {
        return (
            <div className="brackets">
                <div className="brackets__border-left"/>

                {this.addDividerClassToBracketBoxes()}

                <div className="brackets__border-right"/>
            </div>
        );
    }

    private addDividerClassToBracketBoxes() {
        const children = this.props.children;

        if (this.getName(children!.constructor) === this.getName(Object) || ((children as any).length === 1)) {
            return children;
        }

        return (children as any[]).map((child: any, index: number) => {
            if (child) {
                let firstOrLast = "";

                if (index === 0) {
                    firstOrLast = "first";
                }

                if (index === (children as any[]).length - 1) {
                    firstOrLast = "last";
                }

                return React.cloneElement(child, {
                    key: index,
                    className: `brackets__box__with-divider ${firstOrLast} ${child.props.className || ""}`,
                });
            }
        });
    }

    // noinspection JSMethodCanBeStatic
    private getName(obj: any) {
        return obj.name || (obj.toString().match(/function (.+?)\(/) || [undefined, ""])[1];
    }
}

interface IBracketsBoxProps {
    className?: string;
}

export class BracketsBox extends React.Component<IBracketsBoxProps, any> {
    public render() {
        return (
            <div className={`brackets__box ${this.props.className || ""}`}>
                {this.props.children}
            </div>
        );
    }
}

export class BracketsHeader extends React.Component<any, any> {
    public render() {
        return (
            <div className="brackets__header">
                {this.props.children}
            </div>
        );
    }
}
