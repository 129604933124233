import moment = require("moment");
import {ComponentRating} from "../../model/ComponentRating";
import {Manager} from "../../model/manager/Manager.model";
import {OperationalDueDiligenceRatings, ProductOddRating} from "../../model/product/OperationalDueDiligenceRatings";
import {Product, Rating} from "../../model/product/Product.model";
import {ProductSummary} from "../../model/product/ProductSummary";
import {Ticker} from "../../model/product/Ticker";
import {NR_RATING} from "../../utils/ratingConstants";

export const mapProductSummary = (productSummaryData: any) => {
    return new ProductSummary(
        productSummaryData.ratingType,
        productSummaryData.backstopId,
        productSummaryData.name,
        new Manager(productSummaryData.managerBackstopId, productSummaryData.managerName),
        productSummaryData.currentRating,
        parseDate(productSummaryData.currentRatingEffectiveDate, productSummaryData.backstopId),
        productSummaryData.assetType,
        productSummaryData.nameForSearch,
        productSummaryData.tickers.map(mapTicker),
        productSummaryData.inPortfolio,
        productSummaryData.region,
        productSummaryData.category,
        productSummaryData.style,
        productSummaryData.approach,
        getOddRating(productSummaryData.oddRating),
    );
};

function parseDate(dateToParse: string, backstopId: number): Date {
    const momentDate = moment(dateToParse);
    if (!momentDate.isValid()) {
        throw new Error(`Unparsable date ${dateToParse} for Product with id ${backstopId}`);
    }

    return momentDate.toDate();
}

function getOddRating(oddRatingJson: any): ProductOddRating {
    return oddRatingJson
        ? new ProductOddRating(oddRatingJson.rating, oddRatingJson.date)
        : new ProductOddRating(NR_RATING, null);
}

const mapTicker = (tickerData: { name: string, shareClass: string }) => {
    return new Ticker(tickerData.name, tickerData.shareClass);
};

export const mapProduct = (productResponse: any): Product => {
    const businessRating = new ComponentRating(
        productResponse.businessRating.rating,
        productResponse.businessRating.comments,
        productResponse.businessRating.ratingPrevious,
        productResponse.businessRating.movement,
    );
    const staffRating = new ComponentRating(
        productResponse.staffRating.rating,
        productResponse.staffRating.comments,
        productResponse.staffRating.ratingPrevious,
        productResponse.staffRating.movement,
    );
    const processRating = new ComponentRating(
        productResponse.processRating.rating,
        productResponse.processRating.comments,
        productResponse.processRating.ratingPrevious,
        productResponse.processRating.movement,
    );
    const riskRating = new ComponentRating(
        productResponse.riskRating.rating,
        productResponse.riskRating.comments,
        productResponse.riskRating.ratingPrevious,
        productResponse.riskRating.movement,
    );
    const performanceAnalysisRating = new ComponentRating(
        productResponse.performanceAnalysisRating.rating,
        productResponse.performanceAnalysisRating.comments,
        productResponse.performanceAnalysisRating.ratingPrevious,
        productResponse.performanceAnalysisRating.movement,
    );
    const termsAndConditionsRating = new ComponentRating(
        productResponse.tncRating.rating,
        productResponse.tncRating.comments,
        productResponse.tncRating.ratingPrevious,
        productResponse.tncRating.movement,
    );
    const esgRating = productResponse.esgRating
        ? new ComponentRating(
            productResponse.esgRating.rating,
            productResponse.esgRating.comments,
            productResponse.esgRating.ratingPrevious,
            productResponse.esgRating.movement,
        )
        : null;
    const currentRating = new Rating(
        productResponse.currentRating.value,
        productResponse.currentRating.date,
        productResponse.currentRating.ratingType,
    );
    const previousRating = productResponse.previousRating && new Rating(
        productResponse.previousRating.value,
        productResponse.previousRating.date,
        productResponse.previousRating.ratingType,
    );
    return new Product(
        productResponse.backstopId,
        productResponse.name,
        productResponse.managerBackstopId,
        businessRating,
        staffRating,
        processRating,
        riskRating,
        performanceAnalysisRating,
        termsAndConditionsRating,
        esgRating,
        currentRating,
        previousRating,
        productResponse.overallRatingComments,
        mapOperationalDueDiligence(productResponse.oppDueDiligence),
        productResponse.monitoringPoints);
};

const mapOperationalDueDiligence = (oppDueDiligenceRatings: any) => {
    return oppDueDiligenceRatings && new OperationalDueDiligenceRatings(
        oppDueDiligenceRatings.rating,
        oppDueDiligenceRatings.ratingComments,
        oppDueDiligenceRatings.ratingPrevious,
        oppDueDiligenceRatings.ratingEffectiveDate,
        oppDueDiligenceRatings.previousRatingEffectiveDate,
    );
};
