import {IInformBarChartValue, IValueByPeriod} from "../../model/inform/InformSummary";
import {IRangeValue} from "../../api/InFormReportingApi";
import {formatShortDate} from "../../utils/dateUtil";
import {AonColors} from "../../../../css/Colors";
import {ESGQuestionOutcome, IInformESGQuestion} from "../../model/inform/InformESG";

export const NOT_APPLICABLE = "N/A";

export interface IValueCompletion {
    value: number,
    name: string,
    color: string
}

export interface ICumulativeAbsoluteReturnValue {
    product: number,
    benchmark: number,
    period: string
}

export enum COMPLETION_COLORS {
    Completed = "#0084BB",
    Pending = "#DDE2E9"
}

export const validRelativePerformanceData = (data: IValueByPeriod[]) => {
    if (data.length === 0) {
        return false;
    }
    return data.find((it) => it.value !== "N/A") !== undefined;
};

export const getRelativePerformanceChartData = (data: IValueByPeriod[]): IInformBarChartValue[] => {
    return data.map((it) => {
        const value = it.value === NOT_APPLICABLE ? 0.00 : +it.value;
        return {
            period: it.period,
            value: it.value,
            valueAsNumber: value,
            label: it.value !== NOT_APPLICABLE ? it.value + "%" : it.value
        };
    });
};

export const getMonthlyExcessReturnChartData = (data: IRangeValue[]): IInformBarChartValue[] => {
    return data.map((it) => {
        const valueAsString = it.value.replace("%", "");
        const valueAsNumber = getNumber(valueAsString);
        return {
            period: formatShortDate(it.date),
            value: valueAsNumber.toFixed(2),
            valueAsNumber,
            label: getLabel(valueAsString)
        };
    });
};

export const getCumulativeAbsoluteReturnChartData = (productData: IRangeValue[], benchmarkData: IRangeValue[]):
    ICumulativeAbsoluteReturnValue[] => {
    return productData.map((productValue: IRangeValue) => {
        return {
            period: formatShortDate(productValue.date),
            product: getNumberFromPercentString(productValue.value),
            benchmark: getRangeValueByDate(benchmarkData, productValue.date)
        };
    });

};

const getRangeValueByDate = (data: IRangeValue[], date: Date) => {
    const rangeValue = data.find((rangeValue) => rangeValue.date === date);
    return rangeValue?.value ? getNumberFromPercentString(rangeValue.value) : 0.00;
};

const getNumberFromPercentString = (percentString: string): number => {
    return getNumber(percentString.replace("%", ""));
};

const getNumber = (value: any): number => {
    return isNaN(value) ? 0.00 : +(Number(value).toFixed(2));
};
const getLabel = (value: any): string => {
    return isNaN(value) ? NOT_APPLICABLE : `${Number(value).toFixed(2)}%`;
};

export const getMinValue = (data: IInformBarChartValue[]): number => {
    return Math.min(...data.map((chartValue) => chartValue.valueAsNumber));
};
export const getDataCompletionChartData = (completion: string | undefined): IValueCompletion[] => {
    const completionNumber = completion ? Math.round(parseFloat(completion.slice(0, 5))) : 0;
    return [
        {
            name: "Completed",
            value: completionNumber,
            color: COMPLETION_COLORS.Completed
        },
        {
            name: "Pending",
            value: 100 - completionNumber,
            color: COMPLETION_COLORS.Pending
        }
    ];
};

export const getBarColor = (value: number) => {
    if (value > 0) {
        return AonColors.AonSuccessGreen;
    } else if (value === 0) {
        return AonColors.AonGray02;
    } else {
        return AonColors.AonErrorRed;
    }
};

export const formatInViewDate = (dateString: string): string => {
    const [day, month, year] = dateString.split('/');
    return formatShortDate(new Date(`${year}-${month}-${day}T00:00:00`));
};

export const mockResizeObserver = () => {
    global.ResizeObserver = jest.fn().mockImplementation(() => {
        return {
            observe: jest.fn(),
            disconnect: jest.fn(),
            unobserve: jest.fn()
        };
    });
};

export const formatXAxisPeriodToYear = (value: string) => {
    if (value.split(" ").length < 3) {
        return "";
    }
    return value.split(" ")[2];
};

export const filterQuestionsByOutcome = (outcome: ESGQuestionOutcome, questions?: IInformESGQuestion[]) => {
    return questions?.filter((question) => question.outcome === outcome);
};
