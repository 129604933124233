import * as React from "react";
import {connect} from "react-redux";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import {hasPlans} from "../../../mainReducerMapSelectors";
import {createInitializeUserSessionAction} from "./userSessionInitializerActions";
import {IApplicationRootState} from "../../../applicationState";

export interface IInitializeUserSessionActions {
    actions: {
        initializeUserSession: () => void;
    };
}

export interface IInitializeUserSessionProps {
    readonly hasPlans: boolean;
}

export type IInitializeUserSessionPropsActions = IInitializeUserSessionActions & IInitializeUserSessionProps;

export const InitializeUserSessionComponent: React.FunctionComponent<IInitializeUserSessionPropsActions> = (props) => {
    props.actions.initializeUserSession();

    if (!props.hasPlans) {
        return null;
    }

    return <div data-testid="initialize-user-session">{props.children}</div>;
};

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IInitializeUserSessionActions => {
    return {
        actions: bindActionCreators({
            initializeUserSession: createInitializeUserSessionAction,
        }, dispatch),
    };
};

export const mapStateToProps = (state: IApplicationRootState): IInitializeUserSessionProps => {
    return {
        hasPlans: hasPlans(state),
    };
};

const connector = connect<IInitializeUserSessionProps, IInitializeUserSessionActions>(
    mapStateToProps,
    mapDispatchToProps,
);

const connectedComponent = connector(InitializeUserSessionComponent);

export default connectedComponent;
